<template>

  <base-container>
    <div class="flex justify-between mb-5">
      <base-heading>
        <div v-if="chapter.icon" class="title-icon-with-background">
          <phosphor-icon :icon="chapter.icon" />
        </div>
        {{ chapter.name }}
      </base-heading>
      <base-button v-if="!locked" class="mb-auto rounded-lg" type="icon" look="secondary" @click="onAddReportPosition()">
        <base-icon>add</base-icon>
      </base-button>
    </div>
    <spinner v-if="positionsLoading" />

    <div v-else>
      <div v-if="reportPositions.length == 0">
        <p class="text-bodyMediumBold">Keine Einträge vorhanden</p>
      </div>
      <div v-else>

        <!-- Desktop Table -->
        <div class="hidden md:block">
          <div v-if="reportPositions" class="grid grid-cols-[auto_1fr_1fr_auto_auto_auto] auto-rows-[minmax(min-content,max-content)]">

            <!-- Header -->
            <div class="contents mx-2 my-5 md:my-2 items-center">

              <div class="bg-grey-mid pr-8 pl-4 py-5 border border-r-0 border-grey-mid mb-2 rounded-l-md">
                Datum
              </div>
              <div class="bg-grey-mid pr-8 pl-0 py-5 border-t border-b border-grey-mid mb-2">
                Ladeort
              </div>
              <div class="bg-grey-mid pr-8 pl-0 py-5 border-t border-b border-grey-mid mb-2">
                Abladeort
              </div>
              <div class="pr-8 pl-0 py-7 sm:py-4 border-t border-b border-grey-mid bg-grey-mid mb-2">
                Menge
              </div>
              <div class="pr-8 pl-0 py-7 sm:py-4 border-t border-b border-grey-mid bg-grey-mid mb-2">
                Einheitspreis
              </div>
              <div class="pr-4 pl-0 sm:py-4 text-right border border-l-0 rounded-r-md border-grey-mid bg-grey-mid mb-2">
                Total
              </div>

            </div>

            <!-- Content -->
            <div v-for="(position, index) in reportPositions" :key="index" class="contents mx-2 my-2 items-center" :class="`${locked ? '' : 'row hover:cursor-pointer hover:text-primary'}`">

              <div class="pr-8 pl-4 py-5 border border-r-0 border-grey-mid mt-2 mb-2 rounded-l-md"
                   @click="onEditReportPosition(position.id)">
                {{ position.date ? DateConverter.convertToLocalDate(position.date) : '-' }}
              </div>

              <div class="pr-8 pl-0 py-5 border-t border-b border-grey-mid mt-2 mb-2"
                   @click="onEditReportPosition(position.id)"
                   v-html="Utils.getFormattedBreakLines(Utils.getPickupAndDropLocation(position.description ?? 'Ladeort').pickup)">
              </div>

              <div class="pr-8 pl-0 py-5 border-t border-b border-grey-mid mt-2 mb-2"
                   @click="onEditReportPosition(position.id)"
                   v-html="Utils.getFormattedBreakLines(Utils.getPickupAndDropLocation(position.description ?? 'Abladeort').drop)">
              </div>

              <div class="pr-8 pl-0 py-5 border-t border-b border-grey-mid mt-2 mb-2"
                   @click="onEditReportPosition(position.id)">
                {{ position.entries[0].quantity }} {{ Type.getUnit(position.entries[0].unitId).abbreviation() }}
              </div>

              <div class="pr-8 pl-0 py-5 border-t border-b border-grey-mid mt-2 mb-2"
                   @click="onEditReportPosition(position.id)">
                {{ Utils.formatCurrency(position.entries[0].unitPrice) }} {{ TenantSettings.getCurrency() }}/{{ Type.getUnit(position.entries[0].unitId).abbreviation() }}
              </div>

              <div class="pr-4 pl-0 py-5 border border-l-0 mt-2 !text-right mb-2 rounded-r-md border-grey-mid"
                   @click="onEditReportPosition(position.id)">
                {{ Utils.formatCurrency(position.entries[0].total ? position.entries[0].total : position.entries[0].unitPrice * position.entries[0].quantity) }} {{ TenantSettings.getCurrency() }}
              </div>

            </div>
          </div>
        </div>

        <!-- Mobile Table -->
        <div class="block md:hidden">
          <div v-if="reportPositions" class="grid grid-cols-[1fr_auto] auto-rows-[minmax(min-content,max-content)]">
            <!-- Header -->
            <div class="contents mx-2 my-5 md:my-2 items-center">
              <div class="pl-3 pr-0 py-2 border border-r-0 rounded-l border-grey-mid bg-grey-mid mb-2">
                <div class="text-bodyMediumBold">Datum</div>
                <div>Menge</div>
              </div>
              <div class="pr-4 pl-0 py-2 text-right border border-l-0 rounded-r border-grey-mid bg-grey-mid mb-2">
                <div class="text-bodyMediumBold">Ladeort</div>
                <div>Abladeort</div>
              </div>
            </div>

            <div v-for="(position, index) in reportPositions" :key="index" class="contents mx-2 my-2 hover:cursor-pointer items-center row">
              <!-- Content -->
              <div class="pl-3 pr-4 pt-5 pb-3 border border-r-0 border-grey-mid mt-2 mb-2 rounded-l"
                   @click="onEditReportPosition(position.id)">
                <div class="text-bodyMediumBold">
                  {{ position.date ? DateConverter.convertToLocalDate(position.date) : '-' }}
                </div>
                <div>
                  {{ position.entries[0].quantity }} {{ Type.getUnit(position.entries[0].unitId).abbreviation() }}
                </div>
              </div>

              <div class="pr-4 pl-0 py-5 border border-l-0 mt-2 !text-right mb-2 border-grey-mid rounded-r"
                   @click="onEditReportPosition(position.id)">
                <div class="text-bodyMediumBold"
                  v-html="Utils.getFormattedBreakLines(Utils.getPickupAndDropLocation(position.description ?? 'Ladeort').pickup)">
                </div>
                <div 
                  v-html="Utils.getFormattedBreakLines(Utils.getPickupAndDropLocation(position.description ?? 'Abladeort').drop)">
                </div>
              </div>

            </div>
          </div>
        </div>

        <div v-if="totalPages > 1" class="flex mt-2">
          <button v-if="offset + 1 != totalPages" class="hover:text-primary transition-color" @click="onLoadMore">Mehr laden</button>
          <div class="flex flex-row ml-auto">
            <button class="mr-4 transition-colors hover:text-primary" @click="onPreviousPage"><base-icon>navigate_before</base-icon></button>
            <div class="cursor-default">{{offset + 1}} / {{totalPages}}</div>
            <button class="ml-4 transition-colors hover:text-primary" @click="onNextPage"><base-icon>navigate_next</base-icon></button>
          </div>
        </div>

      </div>
    </div>

  </base-container>

</template>

<script setup lang="ts">
import router, { routeNames } from '@/router'
import { Type } from '@/model/Type'
import BaseIcon from '@/components/base/BaseIcon.vue'
import { DateConverter } from '@/client/DateConverter'
import Spinner from '@/components/generel/Spinner.vue'
import { ref, watch } from 'vue'
import { Utils } from '../../client/utils'
import { TenantSettings } from '../../stores/TenantSettings'
import { getReportPage } from '@/service/workReportService'
import type { ReportPosition } from '@/model/ReportPosition'
import PhosphorIcon from '@/components/base/PhosphorIcon.vue'
import { useRoute } from 'vue-router'
import type { Chapter } from '@/model/Chapter'
import type { Invoice } from '@/model/Invoice'
import { UrlData } from '@/client/UrlData'

const props = defineProps<{
  invoice: Invoice;
  projectId: string;
  locked: boolean;
  chapter: Chapter;
}>();

const route = useRoute();

let pageSize = !route.query.pageSize ? 10 : +route.query.pageSize;
const offset = ref(0);
const totalPages = ref(0);
const positionsLoading = ref(false);
const reportPositions = ref<ReportPosition[]>([]);

async function onEditReportPosition(id: string) {
  if (props.locked) return;
  await router.push({
    name: routeNames.EDIT_TRANSPORT_REPORT,
    params: {id: id},
    query: {
      subprojectId: props.invoice.id,
      projectId: props.projectId,
      chapterId: props.chapter?.id ?? '',
      chapters: UrlData.toUrlData(props.invoice.chapters)
    }
  })
}

async function onAddReportPosition() {
  await router.push({
    name: routeNames.CREATE_TRANSPORT_REPORT,
    query: {
      subprojectId: props.invoice.id,
      projectId: props.projectId,
      chapterId: props.chapter?.id ?? '',
      chapters: UrlData.toUrlData(props.invoice.chapters)
    }
  })
}

async function onNextPage() {
  if (offset.value + 1 >= totalPages.value) return;
  offset.value += 1;
  await loadReportPositions();
}

async function onPreviousPage() {
  if (offset.value == 0) return;
  offset.value -= 1;
  await loadReportPositions()
}

async function onLoadMore() {

  pageSize += 15;
  router.replace({
    path: route.path,
    query: {
      ...route.query,
      pageSize: pageSize
    }
  });
  await loadReportPositions()
}

async function loadReportPositions(setLoading: boolean = false) {
  if (setLoading) positionsLoading.value = true;
  const response = await getReportPage(props.invoice.id, pageSize, offset.value, props.chapter.id);
  positionsLoading.value = false;
  if (!response) return;
  totalPages.value = response.totalPages;
  reportPositions.value = response.page;
}

watch(() => props.invoice.id, newVal => {
  if (!newVal) return;
  offset.value = 0;
  loadReportPositions(true);
}, {
  immediate: true
});

</script>

<style scoped>

</style>