
export class UrlData {

  static toUrlData(data: any) {
    return btoa(JSON.stringify(data))
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=+$/, '');
  }
  
  static toData<T>(base64: string): T {
    // Pad the base64 string to make its length a multiple of 4
    while (base64.length % 4) {
      base64 += '=';
    }
    const data = atob(
      base64.replace(/-/g, '+').replace(/_/g, '/')
    );
    return JSON.parse(data) as T
  }

}