<template>
  <base-site title="Einstellungen">
    <spinner v-if="loading" />

    <template v-else>
      <base-container class="mb-betweenElements">
        <base-heading>
          <div class="title-icon-with-background">
            <phosphor-icon icon="gear" />
          </div>
          Allgemein
        </base-heading>
        <FormKit
          type="select"
          label="Währung*"
          :options="currencyOptions"
          v-model="defaultCurrency"
        />
        <p class="text-bodyMediumBold mb-1">Standart Mehrwertsteuer Einstellung*</p>
        <radio-select ref="vatTypeRadioSelect"
                      :options="[{label: 'Preise exklusive Mehrwertsteuer', value: false}, {label: 'Preise inklusive Mehrwertsteuer', value: true}]"
                      :init-option="pricesIncludingVat"
                      orientation="horizontal"
                      @on-select="value => pricesIncludingVat = value" />
        <chapter-editor ref="chapterEditor" title="Standart Phasen" :initial-chapters="chapters" />
      </base-container>

      <base-container class="mb-betweenElements">
        <base-heading>
          <div class="title-icon-with-background">
            <phosphor-icon icon="file-pdf" />
          </div>
          PDF Format
        </base-heading>

        <div class="flex flex-col md:flex-row justify-between">
          <div class="w-full md:mr-betweenElements"><FormKit type="number" number inputmode="decimal" label="Abstand Seite oben bis Logo*" v-model="distanceTopLogo" /></div>
          <div class="w-full"><FormKit type="number" number inputmode="decimal" label="Logohöhe*" v-model="logoHeight" /></div>
        </div>
        <div class="flex flex-col md:flex-row justify-between">
          <div class="w-full md:mr-betweenElements"><FormKit type="number" number inputmode="decimal" label="Abstand Logo bis Adressen*" v-model="distanceLogoAddresses" /></div>
          <div class="w-full"><FormKit type="number" number inputmode="decimal" label="Abstand zwischen Addressen*" v-model="distanceBetweenAddresses" /></div>
        </div>
        <div class="flex flex-col md:flex-row justify-between">
          <div class="w-full"><FormKit type="number" number inputmode="decimal" label="Unterschriftshöhe*" v-model="signatureHeight" /></div>
        </div>
      </base-container>

      <base-container class="mb-betweenElements">
        <base-heading>
          <div class="title-icon-with-background">
            <phosphor-icon icon="currency-dollar" />
          </div>
          Rechnung
        </base-heading>

        <FormKit type="text" label="Rechnungstitel*" v-model="invoiceTitle" />
        <div class="border border-grey-mid rounded p-5 mb-betweenElements">
          <FormKit type="textarea" auto-height label="Einleitungstext" v-model="invoiceHeader" />
          <dropdown label="Schlüssel für den Einleitungstext">
            <InvoiceKeysTable
              class="mt-betweenElements"
              :on-click="onAddKeyToHeader"
              :entity="CustomFieldEntity.SUBPROJECT" />
          </dropdown>
        </div>

        <div class="flex flex-col md:flex-row justify-between">
          <div class="w-full md:mr-betweenElements"><FormKit type="text" label="Tabellentitle Arbeitsrapport*" v-model="workTableTitle" /></div>
          <div class="w-full"><FormKit type="text" label="Tabellentitle Materialrapport*" v-model="materialTableTitle" /></div>
        </div>
        <div class="flex flex-col md:flex-row justify-between">
          <div class="w-full md:mr-betweenElements"><FormKit type="number" number inputmode="decimal" label="Standard Zahlungsfrist in Tagen*" v-model="paymentDeadline" /></div>
          <div class="w-full"><FormKit type="number" number inputmode="decimal" label="Standard Skonto-Frist in Tagen*" v-model="skontoRuntime" /></div>
        </div>

        <div class="border border-grey-mid rounded p-5 mb-betweenElements">
          <FormKit type="textarea" auto-height label="Abschlusstext" v-model="invoiceFooter" />
          <dropdown label="Schlüssel für den Abschlusstext">
            <InvoiceKeysTable
              class="mt-betweenElements"
              :on-click="onAddKeyToFooter"
              :entity="CustomFieldEntity.SUBPROJECT"
            />
          </dropdown>
        </div>
      </base-container>

      <base-container v-if="!TenantSettings.isTransport()" class="mb-betweenElements">
        <base-heading>
          <div class="title-icon-with-background">
            <phosphor-icon icon="handshake" />
          </div>
          Offerte
        </base-heading>

        <FormKit type="text" label="Offertentitel*" v-model="offerTitle" />
        <div class="border border-grey-mid rounded p-5 mb-betweenElements">
          <FormKit type="textarea" auto-height label="Einleitungstext" v-model="offerHeader" />
          <dropdown label="Schlüssel für den Einleitungstext">
            <InvoiceKeysTable
              class="mt-betweenElements"
              :on-click="onAddKeyToOfferHeader"
              :entity="CustomFieldEntity.OFFER" />
          </dropdown>
        </div>

        <div class="flex flex-col md:flex-row justify-between">
          <div class="w-full md:mr-betweenElements"><FormKit type="text" label="Tabellentitle Arbeitspositionen*" v-model="workOfferTableTitle" /></div>
          <div class="w-full"><FormKit type="text" label="Tabellentitle Materialpositionen*" v-model="materialOfferTableTitle" /></div>
        </div>

        <div class="border border-grey-mid rounded p-5 mb-betweenElements">
          <FormKit type="textarea" auto-height label="Abschlusstext" v-model="offerFooter" />
          <dropdown label="Schlüssel für den Abschlusstext">
            <InvoiceKeysTable
              class="mt-betweenElements"
              :on-click="onAddKeyToOfferFooter"
              :entity="CustomFieldEntity.OFFER" />
          </dropdown>
        </div>
      </base-container>

      <base-container class="mb-betweenElements">
        <base-heading>
          <div class="title-icon-with-background">
            <phosphor-icon icon="headset" />
          </div>
          Sprache zu Text
        </base-heading>

        <FormKit type="select" label="Übersetzungssprache*" :options="aiLanguageOptions" v-model="aiLanguage" />
        <FormKit type="textarea" auto-height label="Zusätzliche Infos zur Übersetzung (Prompt)" v-model="aiPrompt" />

      </base-container>

      <div class="flex md:flex-row flex-col">
        <base-button class="md:mr-auto md:mb-0 md:mx-0 mx-betweenElements md:w-buttonXLarge" @click="updateSettings">
          <spinner-small v-if="requesting" />
          <div v-else>Speichern</div>
        </base-button>
        <base-button class="md:ml-auto md:mx-0 mx-betweenElements mt-8 md:mt-0 md:w-buttonXLarge" look="secondary-light" @click="onCancel">
          Abbrechen
        </base-button>
      </div>
    </template>
  </base-site>
</template>

<script setup lang="ts">
import BaseSite from '@/components/base/BaseSite.vue'
import BaseContainer from '@/components/base/BaseContainer.vue'
import BaseIcon from '@/components/base/BaseIcon.vue'
import { onMounted, ref } from 'vue'
import Spinner from '@/components/generel/Spinner.vue'
import SpinnerSmall from '@/components/generel/SpinnerSmall.vue'
import BaseButton from '@/components/base/BaseButton.vue'
import { API, PathSegment } from '@/client/axios'
import type Settings from '@/model/Settings'
import router from '@/router'
import InvoiceKeysTable from '@/components/settings/InvoiceKeysTable.vue'
import { TenantSettings } from '@/stores/TenantSettings'
import PhosphorIcon from '@/components/base/PhosphorIcon.vue'
import { Currency } from '@/model/Type'
import { CustomFieldEntity } from '@/model/CustomField'
import RadioSelect from '@/components/generel/RadioSelect.vue'
import Dropdown from '@/components/generel/Dropdown.vue'
import ChapterEditor from '@/components/invoice/ChapterEditor.vue'
import type { ChapterRequest } from '@/model/Chapter'

const loading = ref(true)
const requesting = ref(false)
const priceIncludingVatOptions = [
  { label: 'Preise exklusive Mehrwertssteuer', value: false },
  { label: 'Preise inklusive Mehrwertssteuer', value: true }
]
const currencyOptions = [
  { label: Currency.CHF, value: Currency.CHF },
  { label: Currency.EUR, value: Currency.EUR },
  { label: Currency.USD, value: Currency.USD }
]
const aiLanguageOptions = [
  { label: 'Deutsch', value: 'de' },
  { label: 'Francais', value: 'fr' },
  { label: 'English', value: 'en' }
]

const defaultCurrency = ref(Currency.CHF)
const pricesIncludingVat = ref(false)
const paymentDeadline = ref(10)
const skontoRuntime = ref(7)
const distanceTopLogo = ref(0)
const logoHeight = ref(45)
const signatureHeight = ref(45)
const distanceLogoAddresses = ref(30)
const distanceBetweenAddresses = ref(50)
const invoiceTitle = ref('Rechnung')
const invoiceHeader = ref('')
const workTableTitle = ref('Arbeiten')
const materialTableTitle = ref('Material')
const invoiceFooter = ref('')
const aiLanguage = ref('de')
const aiPrompt = ref('Arbeitsrapport')

const offerTitle = ref('Offerte')
const offerHeader = ref('')
const workOfferTableTitle = ref('Arbeiten')
const materialOfferTableTitle = ref('Material')
const offerFooter = ref('')

const chapterEditor = ref(ChapterEditor);
const chapters = ref<ChapterRequest[]>([])

async function loadSettings() {
  loading.value = true
  const response = await API.getDataObject<Settings>(PathSegment.SETTINGS, '', 'Einstellungen')
  if (!response) return

  defaultCurrency.value = response.defaultCurrency
  pricesIncludingVat.value = response.pricesIncludingVat
  paymentDeadline.value = response.paymentDeadline
  skontoRuntime.value = response.skontoRuntime
  distanceTopLogo.value = response.distanceTopLogo
  logoHeight.value = response.logoHeight
  signatureHeight.value = response.signatureHeight
  distanceLogoAddresses.value = response.distanceLogoAddress
  distanceBetweenAddresses.value = response.distanceBetweenAddresses
  invoiceTitle.value = response.invoiceTitle
  invoiceHeader.value = response.invoiceHeader ?? ''
  workTableTitle.value = response.invoiceWorkTableTitle
  materialTableTitle.value = response.invoiceMaterialTableTitle
  invoiceFooter.value = response.invoiceFooter ?? ''
  offerTitle.value = response.offerTitle
  offerHeader.value = response.offerHeader ?? ''
  workOfferTableTitle.value = response.offerWorkTableTitle
  materialOfferTableTitle.value = response.offerMaterialTableTitle
  offerFooter.value = response.offerFooter ?? ''
  aiLanguage.value = response.aiLanguage
  aiPrompt.value = response.aiPrompt

  chapters.value = response.chapters

  loading.value = false
}

async function updateSettings() {
  if (!chapterEditor.value) return;
  requesting.value = true
  const body: Settings = {
    tenant: '',
    defaultCurrency: defaultCurrency.value,
    pricesIncludingVat: pricesIncludingVat.value,
    paymentDeadline: paymentDeadline.value,
    skontoRuntime: skontoRuntime.value,
    distanceTopLogo: distanceTopLogo.value,
    logoHeight: logoHeight.value,
    signatureHeight: signatureHeight.value,
    distanceLogoAddress: distanceLogoAddresses.value,
    distanceBetweenAddresses: distanceBetweenAddresses.value,
    invoiceTitle: invoiceTitle.value,
    invoiceHeader: invoiceHeader.value == '' ? undefined : invoiceHeader.value,
    invoiceWorkTableTitle: workTableTitle.value,
    invoiceMaterialTableTitle: materialTableTitle.value,
    invoiceFooter: invoiceFooter.value == '' ? undefined : invoiceFooter.value,
    offerTitle: offerTitle.value,
    offerHeader: offerHeader.value == '' ? undefined : offerHeader.value,
    offerWorkTableTitle: workOfferTableTitle.value,
    offerMaterialTableTitle: materialOfferTableTitle.value,
    offerFooter: offerFooter.value == '' ? undefined : offerFooter.value,
    aiLanguage: aiLanguage.value,
    aiPrompt: aiPrompt.value,
    updatedAt: 0,
    useCase: TenantSettings.get.useCase,
    chapters: chapterEditor.value.getChapters()
  }
  const response = await API.updateDataObject<Settings>(PathSegment.SETTINGS, body, 'Einstellungen')
  if (response) {
    TenantSettings.setSettings(response)
    router.go(-1)
  }
  requesting.value = false
}

function onCancel() {
  router.go(-1)
}

function onAddKeyToHeader(key: string) {
  invoiceHeader.value +=
    invoiceHeader.value[invoiceHeader.value.length - 1] == ' ' ? key : ` ${key}`
}

function onAddKeyToFooter(key: string) {
  invoiceFooter.value +=
    invoiceFooter.value[invoiceFooter.value.length - 1] == ' ' ? key : ` ${key}`
}

function onAddKeyToOfferHeader(key: string) {
  offerHeader.value +=
    offerHeader.value[offerHeader.value.length - 1] == ' ' ? key : ` ${key}`
}

function onAddKeyToOfferFooter(key: string) {
  offerFooter.value +=
    offerFooter.value[offerFooter.value.length - 1] == ' ' ? key : ` ${key}`
}

onMounted(() => {
  loadSettings()
})
</script>

<style scoped></style>