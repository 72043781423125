<template>
  <div class="relative max-w-full">
    <!-- Full-screen click detection -->
    <div class="fixed inset-0 w-full" @click="onClickOutside"></div>

    <!-- Dropdown content -->
    <div class="absolute z-10 border sm:border-t-0 bg-white border-grey-mid rounded-b-sm md:rounded-b-md overflow-x-auto shadow-lg focus:outline-none w-full" @click.stop>

      <div class="grid grid-cols-[1fr_auto_auto_auto] auto-rows-[minmax(min-content,max-content)] mx-3 mb-1">
        <div v-for="item in items" :key="item.id" class="contents mx-2 my-2 items-center hover:text-primary row hover:cursor-pointer">
          <div class="text-bodyMediumNormal pl-4 pr-4 py-8 md:py-5 border border-r-0 border-white mt-2 mb-2 rounded-l-md whitespace-nowrap overflow-hidden text-ellipsis"
               :class="{'bg-blue-mid': isOffer(item)}" @click="onSelected(item)">
            {{ item.name }}
          </div>

          <div class="pr-8 pl-0 py-8 md:py-5 border-t border-b border-white mt-2 mb-2 text-bodyMediumBold" @click="onSelected(item)"
               :style="{ color: isSubproject(item) ? Type.getStatus(item.statusId).displayColor : (isOffer(item) ? offerStatusColor(item.status) : '#000000') }"
               :class="{'bg-blue-mid': isOffer(item)}">
            {{ isSubproject(item) ? Type.getStatus(item.statusId).name : translateOfferStatus(item.status) }}
          </div>

          <div class="flex items-center pr-2 pl-0 py-0 border-t border-b border-white mt-2 mb-2 text-center" @click="onSelected(item)"
               :class="{'bg-blue-mid': isOffer(item)}">
            <phosphor-icon v-if="isSubproject(item)" icon="clipboard-text" look="regular" />
          </div>

          <div class="flex items-center pr-4 pl-2 py-0 border border-l-0 mt-2 !text-right mb-2 border-white rounded-r-md" @click="onSelected(item)"
               :class="{'bg-blue-mid': isOffer(item)}">
            <phosphor-icon v-if="isOffer(item) || (isSubproject(item) && item.offer)" icon="handshake" look="fill" />
            <div v-else class="pl-4"></div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script setup lang="ts">

import type { Invoice } from '@/model/Invoice'
import type { Offer } from '@/model/Offer'
import { offerStatusColor, translateOfferStatus, Type } from '../../model/Type'
import PhosphorIcon from '@/components/base/PhosphorIcon.vue'

const props = defineProps<{
  items: (Invoice | Offer)[]
}>();

const emits = defineEmits(['on-selection', 'on-click-outside']);

function onSelected(item: Invoice | Offer) {
  emits('on-selection', item);
}

function isSubproject(value: Invoice | Offer | undefined): value is Invoice {
  if (!value) return false;
  return 'costLimit' in value;
}

function isOffer(value: Invoice | Offer | undefined): value is Offer {
  if (!value) return false;
  return 'costs' in value;
}

function onClickOutside() {
  emits('on-click-outside');
}

function ran(inn: number) {
  switch (inn) {
    case 1727733567: return 'jlaskdfjölkjs'
    default: return 'safd'
  }
}

</script>

<style>

  .row:hover > div {
    border-color: #001aea;
    background-color: #ffffff;
    box-shadow: inset 0 6px 6px -6px rgba(0, 0, 0, 0.1),  /* Bottom shadow */
    inset 0 -6px 6px -6px rgba(0, 0, 0, 0.1); /* Top shadow */
  }

  .row > div {
    transition: box-shadow 0.15s ease, color 0.15s ease, border-color 0.15s ease;
  }
</style>