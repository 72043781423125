import pdfMake from 'pdfmake/build/pdfmake'

import { DateConverter } from '@/client/DateConverter'
import { useToast } from 'vue-toast-notification'
import { Utils } from '@/client/utils'
import { ReportType, Type } from '@/model/Type'
import type InvoiceInfo from '@/model/InvoiceInfo'
import type { PdfFormatInfo } from '@/model/PdfFormatInfo'
import cutLinePng from '@/assets/cut_line.png'
import { API } from '@/client/axios'
import type Customer from '@/model/Customer'
import type Company from '@/model/Company'
import { TenantSettings } from '@/stores/TenantSettings'
import { TenantFields } from '@/stores/TenantFields'
import { CustomFieldEntity, CustomFieldType, type CustomFieldValue } from '@/model/CustomField'
import type { ReportPosition } from '@/model/ReportPosition'
import { VatType } from '@/model/Invoice'
import { ChapterDefault } from '@/model/Chapter'

(pdfMake as any).fonts = {
  Roboto: {
    normal: `${API.apiBaseUrl}fonts/Roboto-Regular.ttf`,
    bold: `${API.apiBaseUrl}fonts/Roboto-Medium.ttf`,
    italics: `${API.apiBaseUrl}fonts/Roboto-Italic.ttf`,
    bolditalics: `${API.apiBaseUrl}fonts/Roboto-MediumItalic.ttf`,
  }
};

export class PdfService {

  static readonly COL_MARGIN = 2;

  static async generateInvoice(invoiceInfo: InvoiceInfo, pdfFormatInfo: PdfFormatInfo) {

    try {
      const docName = `rechnung_${DateConverter.getCurrentLocalDate().replace(/\./g, '_')}_${invoiceInfo.project.name.toLocaleLowerCase().replace(/[^a-zA-Z0-9 ]/g, '').replace(/ /g, '_')}.pdf`;

      const docDefinition: any = {
        info: {
          title: docName
        },
        content: [],
        defaultStyle: {
          columnGap: 20,
        },
        footer: (currentPage: any, pageCount: any) => {
          return {
            text: `Seite ${currentPage} / ${pageCount}`,
            alignment: 'right',
            fontSize: 9,
            margin: [0, currentPage == pageCount ? -270 : 10, 20, 0]
          };
        },
      };

      if (invoiceInfo.base64Logo) docDefinition.content.push(this.getLogo(invoiceInfo.base64Logo, invoiceInfo.settings.logoHeight, invoiceInfo.settings.distanceTopLogo));
      docDefinition.content.push(this.getAddresses(invoiceInfo.company, invoiceInfo.customer, invoiceInfo.settings.distanceLogoAddress, invoiceInfo.settings.distanceBetweenAddresses));
      docDefinition.content.push(this.standardText(`${invoiceInfo.company.address.city}, ${DateConverter.getCurrentLocalDate()}`, 0, 20))
      docDefinition.content.push({ text: invoiceInfo.settings.title, fontSize: 13, margin: [0, 0, 0, 10] });
      if (invoiceInfo.settings.header) docDefinition.content.push(this.standardText(invoiceInfo.settings.header));

      let chapterNumber = 1;
      for (const chapter of invoiceInfo.calculation.chapters) {
        if (chapter.reportPositions.length == 0) continue
        const format = pdfFormatInfo.jobTables.get(chapter.chapterId ?? ChapterDefault.NONE)!;
        docDefinition.content.push(this.getTableTitle(`${chapterNumber}. ${chapter.chapterName ?? 'Allgemein'}`, format.marginBefore, format.pageBreakBefore, invoiceInfo.calculation.chapters.length > 0));
        docDefinition.content.push(this.getReportTableConstructionOrTransport(chapter.reportPositions, chapter.total, chapter.reportType == ReportType.WORK ? CustomFieldEntity.WORK_REPORT_POSITION : CustomFieldEntity.MATERIAL_REPORT_POSITION, format.marginAfter, pdfFormatInfo.tableFontSize));
        chapterNumber += 1;
      }

      docDefinition.content.push(this.getTableTitle('Gesamtbetrag', pdfFormatInfo.totalsTable.marginBefore, pdfFormatInfo.totalAndQrBillOnSameSite || pdfFormatInfo.totalsTable.pageBreakBefore));
      docDefinition.content.push(this.getTotalCostTable(invoiceInfo, pdfFormatInfo.tableFontSize));
      docDefinition.content.push(this.getPaymentConditionsText(invoiceInfo));
      if (invoiceInfo.settings.footer) docDefinition.content.push(this.standardText(invoiceInfo.settings.footer, 20));
      docDefinition.content.push(this.getQrBill(invoiceInfo.base64QrBill, !pdfFormatInfo.totalAndQrBillOnSameSite && !pdfFormatInfo.compactBill));
      docDefinition.content.push(await this.getCutLine());

      return {
        name: docName,
        pdf: pdfMake.createPdf(docDefinition)
      };
    } catch (error) {
      useToast().error(`PDF-Generierung fehlgeschlagen: ${Utils.getError(error)}`);
      console.error(error);
    }
  }

  private static standardText(text: any, marginTop: number = 0, marginBottom: number = 10) {
    return {
      text: text,
      fontSize: 9,
      margin: [0, marginTop, 0, marginBottom] // left, top, right, bottom
    }
  }

  private static getAddresses(company: Company, customer: Customer, marginTop: number, marginBetween: number) {
    return {
      columns: [
        {
          // Company Address
          width: '*',
          text: [
            company.name,
            company.address.street,
            `${company.address.zip} ${company.address.city}`,
            company.uid,
          ].map(val => this.lineBreak(val)).join(''),
          fontSize: 10,
          margin: [0, marginTop, 0, 30]
        },
        {
          width: marginBetween,
          text: ''
        },
        {
          // Customer Address
          width: '*',
          text: [
            customer.companyName,
            `${customer.lastname ? customer.title + '\n' + customer.firstname + ' ' + customer.lastname : ''}`,
            customer.address.street,
            `${customer.address.zip} ${customer.address.city}`
          ].map(val => this.lineBreak(val)).join(''),
          fontSize: 10,
          margin: [0, marginTop, 0, 30]
        }
      ]
    }
  }

  private static getTableTitle(title: string, marginTop: number, pageBreak: boolean, show: boolean = true): any {
    return !show ? {} : {
      text: title,
      fontSize: 11,
      margin: [0, marginTop, 0, 10],
      bold: true,
      ...(pageBreak && { pageBreak: 'before' })
    }
  }

  private static getTableSubtitle(title: string, marginTop: number, pageBreak: boolean = false): any {
    return {
      text: title,
      fontSize: 10,
      margin: [0, marginTop, 0, 5],
      bold: true,
      ...(pageBreak && { pageBreak: 'before' })
    }
  }

  private static getReportTableConstructionOrTransport(positions: ReportPosition[], total: number, entity: CustomFieldEntity, marginBottom: number, fontSize: number): any {
    if (TenantSettings.isTransport()) return this.getTransportReportTable(positions, total, entity, marginBottom, fontSize);
    else return this.getReportTable(positions, total, entity, marginBottom, fontSize);
  }

  private static getReportTable(positions: ReportPosition[], total: number, entity: CustomFieldEntity, marginBottom: number, fontSize: number): any {
    const fieldsPresent = this.fieldsPresent(positions, entity);

    const header: any[] = [
      {text: 'Datum', bold: true, margin: [0,0,this.COL_MARGIN,0]},
      {text: 'Beschreibung', bold: true, margin: [this.COL_MARGIN,0,this.COL_MARGIN,0]},
      ...this.getTableHeaderForCustomFields(entity, fieldsPresent),
      {text: 'Anzahl', bold: true, margin: [this.COL_MARGIN,0,this.COL_MARGIN,0]},
      {text: 'Einheitspreis', bold: true, margin: [this.COL_MARGIN,0,this.COL_MARGIN,0]},
      {text: `Total ${TenantSettings.getCurrency()}`, bold: true, alignment: 'right', noWrap: true, margin: [this.COL_MARGIN,0,0,0]}
    ];

    const body: any[][] = []
    const groupSizes: number[] = [1];
    let currentGroupSize = 1;
    positions.forEach(position => {
      if (Utils.isSingleReportPosition(position)) {
        const entry = position.entries[0]
        body.push([
          {text: `${position.date ? DateConverter.convertToLocalDate(position.date) : ''}`, margin: [0,0,this.COL_MARGIN,0]},
          {text: position.description, margin: [this.COL_MARGIN,0,this.COL_MARGIN,0]},
          ...this.getTableColumnsForCustomFields(entity, position.customFieldValues, fieldsPresent),
          {text: `${entry.quantity} ${Type.getUnit(entry.unitId).abbreviation()}`, noWrap: true, margin: [this.COL_MARGIN,0,this.COL_MARGIN,0]},
          {text: `${this.currency(entry.unitPrice)}/${Type.getUnit(entry.unitId).abbreviation()}`, noWrap: true, margin: [this.COL_MARGIN,0,this.COL_MARGIN,0]},
          {text: Utils.formatCurrency(entry.total ?? 0), alignment: 'right', margin: [this.COL_MARGIN,0,0,0]}
        ]);
        groupSizes.push(currentGroupSize + 1);
        currentGroupSize += 1;
      } else {
        body.push([
          position.date ? DateConverter.convertToLocalDate(position.date) : '',
          position.description,
          ...this.getTableColumnsForCustomFields(entity, position.customFieldValues, fieldsPresent),
          {},
          {},
          {}
        ]);
        position.entries.forEach(entry => {
          body.push([{text: '', margin: [0,0,this.COL_MARGIN,0]},
            {text: '- ' +entry.description, margin: [this.COL_MARGIN,0,this.COL_MARGIN,0]},
            ...this.getEmptyObjectsForCustomFields(entity, fieldsPresent),
            {text: `${entry.quantity} ${Type.getUnit(entry.unitId).abbreviation()}`, noWrap: true, margin: [this.COL_MARGIN,0,this.COL_MARGIN,0]},                 // Worked hours
            {text: `${this.currency(entry.unitPrice)}/${Type.getUnit(entry.unitId).abbreviation()}`, noWrap: true, margin: [this.COL_MARGIN,0,this.COL_MARGIN,0]}, // Price per Hour
            {text: Utils.formatCurrency(entry.total ?? 0), alignment: 'right', margin: [this.COL_MARGIN,0,0,0]}]);                                    // Total
        });
        groupSizes.push(currentGroupSize + position.entries.length + 1);
        currentGroupSize += position.entries.length + 1;
      }
    });

    const totalRow: any[] = [
      {text: 'Total', bold: true, margin: [0,0,this.COL_MARGIN,0]},
      {text: '', margin: [this.COL_MARGIN,0,this.COL_MARGIN,0]},
      ...this.getEmptyObjectsForCustomFields(entity, fieldsPresent),
      {text: '', margin: [this.COL_MARGIN,0,this.COL_MARGIN,0]},
      {text: '', margin: [this.COL_MARGIN,0,this.COL_MARGIN,0]},
      {text: Utils.formatCurrency(total), bold: true, alignment: 'right', margin: [this.COL_MARGIN,0,0,0]}
    ];

    return positions.length == 0 ? {} : {
      fontSize: fontSize,
      table: {
        headerRows: 1,
        widths: ['auto', '*', ...this.getFieldWidthForCustomFields(entity, fieldsPresent), 'auto', 'auto', 'auto'],
        body: [header, ...body, totalRow]
      },
      layout: {
        hLineWidth: function(i: number, node: any) {
          if (groupSizes.includes(i)) return 0.5;
          return 0;
        },
        vLineWidth: (i: number, node: any) => 0,
        paddingTop: (i: number, node: any) => 2,
        paddingBottom: (i: number, node: any) => 2,
      },
      margin: [0, 0, 0, marginBottom]
    }
  }

  private static getTransportReportTable(positions: ReportPosition[], total: number, entity: CustomFieldEntity, marginBottom: number, fontSize: number): any {
    const fieldsPresent = this.fieldsPresent(positions, entity);

    const header: any[] = [
      {text: 'Datum', bold: true, margin: [0,0,this.COL_MARGIN,0]},
      {text: 'Ladeort', bold: true, margin: [this.COL_MARGIN,0,this.COL_MARGIN,0]},
      {text: 'Abladeort', bold: true, margin: [this.COL_MARGIN,0,this.COL_MARGIN,0]},
      ...this.getTableHeaderForCustomFields(entity, fieldsPresent),
      {text: 'Anzahl', bold: true, margin: [this.COL_MARGIN,0,this.COL_MARGIN,0]},
      {text: 'Einheitspreis', bold: true, margin: [this.COL_MARGIN,0,this.COL_MARGIN,0]},
      {text: `Total ${TenantSettings.getCurrency()}`, bold: true, alignment: 'right', noWrap: true, margin: [this.COL_MARGIN,0,0,0]}
    ];

    const body: any[][] = []
    const groupSizes: number[] = [1];
    let currentGroupSize = 1;
    positions.forEach(position => {
      const entry = position.entries[0]
      body.push([
        {text: `${position.date ? DateConverter.convertToLocalDate(position.date) : ''}`, margin: [0,0,this.COL_MARGIN,0]},
        {text: position.description ? Utils.getPickupAndDropLocation(position.description).pickup : '', margin: [this.COL_MARGIN,0,this.COL_MARGIN,0]},
        {text: position.description ? Utils.getPickupAndDropLocation(position.description).drop : '', margin: [this.COL_MARGIN,0,this.COL_MARGIN,0]},
        ...this.getTableColumnsForCustomFields(entity, position.customFieldValues, fieldsPresent),
        {text: `${entry.quantity} ${Type.getUnit(entry.unitId).abbreviation()}`, noWrap: true, margin: [this.COL_MARGIN,0,this.COL_MARGIN,0]},
        {text: `${this.currency(entry.unitPrice)}/${Type.getUnit(entry.unitId).abbreviation()}`, noWrap: true, margin: [this.COL_MARGIN,0,this.COL_MARGIN,0]},
        {text: Utils.formatCurrency(entry.total ?? 0), alignment: 'right', margin: [this.COL_MARGIN,0,0,0]}
      ]);
      groupSizes.push(currentGroupSize + 1);
      currentGroupSize += 1;
    });

    const totalRow: any[] = [
      {text: 'Total', bold: true, margin: [0,0,this.COL_MARGIN,0]},
      {text: '', margin: [this.COL_MARGIN,0,this.COL_MARGIN,0]},
      {text: '', margin: [this.COL_MARGIN,0,this.COL_MARGIN,0]},
      ...this.getEmptyObjectsForCustomFields(entity, fieldsPresent),
      {text: '', margin: [this.COL_MARGIN,0,this.COL_MARGIN,0]},
      {text: '', margin: [this.COL_MARGIN,0,this.COL_MARGIN,0]},
      {text: Utils.formatCurrency(total), bold: true, alignment: 'right', margin: [this.COL_MARGIN,0,0,0]}
    ];

    return positions.length == 0 ? {} : {
      fontSize: fontSize,
      table: {
        headerRows: 1,
        widths: ['auto', '*', '*', ...this.getFieldWidthForCustomFields(entity, fieldsPresent), 'auto', 'auto', 'auto'],
        body: [header, ...body, totalRow]
      },
      layout: {
        hLineWidth: function(i: number, node: any) {
          if (groupSizes.includes(i)) return 0.5;
          return 0;
        },
        vLineWidth: (i: number, node: any) => 0,
        paddingTop: (i: number, node: any) => 2,
        paddingBottom: (i: number, node: any) => 2,
      },
      margin: [0, 0, 0, marginBottom]
    }
  }

  private static getTotalCostTable(invoiceInfo: InvoiceInfo, fontSize: number): any {
    const calculation = invoiceInfo.calculation
    const totals = calculation.totals;
    const hasDiscounts = calculation.discounts.length > 0;

    const tableBody: any = []
    for (const chapter of calculation.chapters) {
      if (chapter.reportPositions.length == 0) continue;
      tableBody.push([`Total ${chapter.chapterName}`, {text: this.currency(chapter.total), alignment: 'right'}])
    }
    if (hasDiscounts && calculation.chapters.length > 1) tableBody.push(['Zwischentotal', {text: this.currency(calculation.chapters.reduce((sum, chapter) => sum + chapter.total, 0)), alignment: 'right'}]);

    calculation.discounts.forEach(item => {
      tableBody.push([
        `${Type.getDiscountType(item.discountTypeId).name}${Type.getDiscountType(item.discountTypeId).relative ? '   ' + (Type.getDiscountType(item.discountTypeId).deduction ? '-' : '+') + ' ' + item.amount + ' %' : ''} ${item.expirationDate != 0 ? '(gültig bis ' + DateConverter.convertToLocalDate(item.expirationDate) + ')' : ''}`,
        {text: `${Type.getDiscountType(item.discountTypeId).deduction ? '-' : '+'} ${this.currency(item.total)}`, alignment: 'right'}
      ]);
    })

    const showVat = invoiceInfo.invoice.vatType != VatType.VAT_FREE;

    if (invoiceInfo.invoice.vatType == VatType.EXCLUSIVE) tableBody.push([{ text: 'Total exklusive Mehrwertsteuer', bold:true }, {text: this.currency(totals.totalBeforeVat), bold: true, alignment: 'right'}]);
    if (showVat) tableBody.push([`Mehrwertsteuer (${invoiceInfo.company.vatRate} %)`, {text: this.currency(totals.totalVat), alignment: 'right'}])
    tableBody.push([{ text: `Total${showVat ? ' inklusive Mehrwertsteuer' : ''}`, bold:true }, {text: this.currency(totals.totalAfterVat), bold: true, alignment: 'right'}]);

    return {
      fontSize: fontSize,
      table: {
        headerRows: 0,
        widths: ['*', '*'],
        body: tableBody,
      },
        layout: {
          hLineWidth: function(i: number, node: any) {
            if (i === 0 || i === node.table.body.length) return 0;
            if (i === node.table.body.length - 1) return 1.5;
            return 0.5;
          },
          vLineWidth: (i: number, node: any) => 0,
          paddingTop: (i: number, node: any) => 2,
          paddingBottom: (i: number, node: any) => 2
        }
      }
  }

  private static getPaymentConditionsText(invoiceInfo: InvoiceInfo) {
    const skonto = invoiceInfo.invoice.skonto;
    const skontoExpiration = invoiceInfo.invoice.skontoExpiration;
    const skontoExpirationText = `bis zum ${skontoExpiration && skontoExpiration ? DateConverter.convertToLocalDate(skontoExpiration) : DateConverter.convertToLocalDate((new Date().getTime() / 1000) + invoiceInfo.settings.skontoRuntime * 24 * 60 * 60)}`
    const skontoText = skonto && skonto > 0 ? `\nBei Zahlung ${skontoExpirationText} werden ${skonto} % Skonto gewährt. ` : '';
    const skontoTotalText = skonto && skonto > 0 ? `Betrag bei Zahlung ${skontoExpirationText} = ${this.currency(invoiceInfo.calculation.totals.totalWithSkonto)}` : '';
    const paymentConditionsText = [
      `Rechnungsbetrag zahlbar innerhalb von ${invoiceInfo.settings.paymentDeadline} Tagen.`,
      skontoText,
      {text: skontoTotalText, bold: true}];
    return this.standardText(paymentConditionsText, 30, 0);
  }

  private static getQrBill(base64QrBill: string, pageBreakBefore: boolean) {
    return {
      svg: base64QrBill,
      width: 595,
      absolutePosition: { x: 5, y: 560 },
      ...((pageBreakBefore) && { pageBreak: 'before' })
    }
  }

  private static async getCutLine() {
    const cutLineImage = await this.convertToBase64(cutLinePng);
    return {
      image: cutLineImage,
      width: 595,
      absolutePosition: { x: 0, y: 555 }
    }
  }

  private static getLogo(base64Logo: string, height: number, marginTop: number) {
    return {
      image: `data:image/png;base64,${base64Logo}`,
      fit: [500, height],
      margin: [0, marginTop, 0, 0]
    }
  }

  private static currency(value: number): string {
    return `${Utils.formatCurrency(value)} ${TenantSettings.getCurrency()}`
  }

  private static async convertToBase64(url: string) {
    return new Promise<string>((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = function() {
        const reader = new FileReader();
        reader.onloadend = function() {
          resolve(reader.result as string);
        };
        reader.onerror = reject;
        reader.readAsDataURL(xhr.response);
      };
      xhr.onerror = reject;
      xhr.open('GET', url);
      xhr.responseType = 'blob';
      xhr.send();
    });
  }

  private static lineBreak(value: string | undefined): string {
    if (!value || value == '') return '';
    return value + '\n';
  }


  // Custom Fields
  private static getFieldWidthForCustomFields(entity: CustomFieldEntity, presence: Map<string, boolean>) {
    if (TenantFields.forEntity(entity).length == 0) return [];
    const columns: any[] = [];
    for (const field of TenantFields.forEntity(entity)){
      if (!presence.get(field.id)) continue;
      columns.push('auto');
    }
    return columns;
  }

  private static getTableHeaderForCustomFields(entity: CustomFieldEntity, presence: Map<string, boolean>) {
    if (TenantFields.forEntity(entity).length == 0) return [];
    const columns: any[] = [];
    for (const field of TenantFields.forEntity(entity)){
      if (!presence.get(field.id)) continue;
      columns.push({text: field.name, bold: true, margin: [this.COL_MARGIN,0,this.COL_MARGIN,0]});
    }
    return columns
  }

  private static getTableColumnsForCustomFields(entity: CustomFieldEntity, fieldValues: CustomFieldValue[] | undefined, presence: Map<string, boolean>) {
    const columns: any[] = [];
    for (const field of TenantFields.forEntity(entity)){
      if (!presence.get(field.id)) continue;
      const value = (fieldValues ?? []).find(v => v.customFieldId == field.id);
      const text = value
        ? (TenantFields.get(value.customFieldId)?.type == CustomFieldType.DATE
          ? DateConverter.convertToLocalDate(value.value)
          : field.charge
            ? field.relative ? `${value.value} %` : this.currency(value.value as unknown as number)
            : value.value)
        : undefined
      columns.push(text ? {text: text, margin: [this.COL_MARGIN,0,this.COL_MARGIN,0]} : {text: '', margin: [this.COL_MARGIN,0,this.COL_MARGIN,0]});
    }
    return columns;
  }

  private static getEmptyObjectsForCustomFields(entity: CustomFieldEntity, presence: Map<string, boolean>) {
    if (TenantFields.forEntity(entity).length == 0) return [];
    const columns: any[] = [];
    for (const field of TenantFields.forEntity(entity)){
      if (!presence.get(field.id)) continue;
      columns.push({ text: '', margin: [this.COL_MARGIN, 0, this.COL_MARGIN, 0] });
    }
    return columns;
  }

  private static fieldsPresent(positions: ReportPosition[], entity: CustomFieldEntity) {
    const valuesPresent = new Map<string, boolean>(TenantFields.forEntity(entity).map(f => [f.id, false]));
    for (const field of TenantFields.forEntity(entity)) {
      if (positions.find(p => p.customFieldValues?.find(v => v.customFieldId == field.id))) {
        valuesPresent.set(field.id, true);
      }
    }
    return valuesPresent;
  }



}