import type { Currency } from '@/model/Type'
import type { ChapterRequest } from '@/model/Chapter'

export default interface Settings {
  tenant: string,
  defaultCurrency: Currency,
  pricesIncludingVat: boolean,
  distanceTopLogo: number,
  distanceLogoAddress: number,
  logoHeight: number,
  signatureHeight: number,
  distanceBetweenAddresses: number,
  paymentDeadline: number,
  skontoRuntime: number,
  invoiceTitle: string,
  invoiceHeader?: string,
  invoiceFooter?: string,
  invoiceWorkTableTitle: string,
  invoiceMaterialTableTitle: string,
  offerTitle: string,
  offerHeader?: string,
  offerFooter?: string,
  offerWorkTableTitle: string,
  offerMaterialTableTitle: string,
  aiLanguage: string,
  aiPrompt: string,
  updatedAt: number,
  useCase: UseCase,
  chapters: ChapterRequest[]
}

export enum UseCase {
  UNDEFINED = 'UNDEFINED',
  CONSTRUCTION = 'CONSTRUCTION',
  TRANSPORT = 'TRANSPORT',
  CONSTRUCTION_CO = 'CONSTRUCTION_CO',
}