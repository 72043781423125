import { ObjectStorage, StorageKey } from '@/service/ObjectStorage'
import type WorkerPreset from '@/model/WorkerPreset'
import { TenantSettings } from '@/stores/TenantSettings'
import { Type } from '@/model/Type'

type PresetsEmitter = (presets: WorkerPreset[]) => undefined;
export class TenantWorkerPresets {

  private static presets: WorkerPreset[];
  private static presetsEmitter: PresetsEmitter[] = [];

  static getPresets() {
    if (!this.presets) this.loadPresetsFromStore();
    return this.presets;
  }

  static getPresetsAsSelectables() {
    return [{label: 'Keine', value: ''},
      ...this.getPresets().map(preset => {
        return {label: `${preset.name} (${preset.pricePerHour} ${TenantSettings.getCurrency()}/${Type.getUnit(preset.unitId).abbreviation()})`, value: preset.id}
      })]
  }

  static getPreselected() {
    return this.getPresets().find(wp => wp.defaultSelected)
  }

  static setPresets(presets: WorkerPreset[]) {
    this.setAndEmitPresets(presets);
  }

  static loadPresetsFromStore() {
    const loaded = ObjectStorage.get<WorkerPreset[]>(StorageKey.WORKER_PRESETS);
    if (!loaded) return false;
    this.setAndEmitPresets(loaded);
    return true;
  }

  static register(emitter: PresetsEmitter) {
    this.presetsEmitter.push(emitter);
    if (this.presets) emitter(this.presets);
  }

  private static setAndEmitPresets(presets: WorkerPreset[]) {
    this.presets = presets;
    this.presetsEmitter.forEach(emit => {
      emit(this.presets);
    })
  }
}