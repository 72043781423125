import type { CustomFieldValue } from '@/model/CustomField'
import type { Offer } from '@/model/Offer'
import type { Chapter, ChapterRequest } from '@/model/Chapter'

export interface Invoice {
  id: string,
  projectId: string,
  name: string,
  costLimit: number,
  currentCosts: number,
  statusId: string,
  referenceNumber: number,
  skonto?: number,
  skontoExpiration?: number,
  chapters: Chapter[],
  customFieldValues?: CustomFieldValue[],
  offer?: Offer,
  vatType: VatType,
  createdAt: number,
  updatedAt: number
}

export interface InvoiceRequest {
  id?: string,
  projectId: string,
  name: string,
  costLimit: number,
  statusId: string,
  skonto?: number,
  skontoExpiration?: number,
  chapters: ChapterRequest[],
  customFieldValues?: CustomFieldValue[],
  offerId?: string,
  vatType: VatType,
}

export enum VatType {
  EXCLUSIVE = 'EXCLUSIVE',
  INCLUSIVE = 'INCLUSIVE',
  VAT_FREE = 'VAT_FREE'
}