<template>
  <div v-if="isVisible" class="modal-container" @click="closeModal">
    <div class="modal-tall-content flex flex-col" @click.stop>

      <!-- Title-->
      <div class="p-8 text-title">Icon wählen</div>

      <!-- Main Content -->
      <div class="px-8 overflow-y-auto flex-grow" style="padding-bottom: 75px;">

        <div v-for="key in iconCategories.keys()" :key="key" class="last:mb-betweenElements">
          <p class="text-title mt-betweenElements mb-3">{{key}}</p>
          <div class="grid grid-cols-4 md:grid-cols-6 gap-6">
            <button
              v-for="icon in iconCategories.get(key)"
              :key="icon"
              @click="onSelect(icon)"
              class="p-7 border flex items-center selectable">
              <phosphor-icon :icon="icon" class="mx-auto"/>
            </button>
          </div>
        </div>

      </div>

      <!-- Buttons -->
      <div class="absolute inset-x-0 bottom-0 rounded-md bg-grey-light">
        <div class="flex">
          <base-button class="flex-1 m-8" look="secondary" @click="closeModal">
            Abbrechen
          </base-button>
        </div>
      </div>
    </div>
  </div>

</template>

<script setup lang="ts">
import BaseButton from '@/components/base/BaseButton.vue'
import { ref } from 'vue'
import PhosphorIcon from '@/components/base/PhosphorIcon.vue'

const isVisible = ref(false);

const toolIcons = ['axe', 'hammer', 'pipe-wrench', 'wrench', 'ladder', 'toolbox', 'nut', 'pencil-ruler',
  'screwdriver', 'shovel', 'broom', 'paint-bucket', 'paint-roller', 'paint-brush-broad', 'paint-brush-household',
  'paint-brush', 'angle', 'compass-tool', 'spray-bottle', 'knife'];

const constructionIcons = ['barricade', 'blueprint', 'hard-hat', 'pipe', 'bulldozer', 'crane', 'crane-tower',
  'wall', 'grid-four', 'grid-nine', 'package', 'compass-tool', 'angle', 'shipping-container', 'trash', 'recycle'];

const vehicleIcons = ['truck', 'truck-trailer', 'car-profile', 'van', 'jeep', 'tractor', 'gas-pump', 'engine', 'motorcycle', 'boat',
  'tire', 'package', 'drop', 'bicycle', 'steering-wheel', 'traffic-cone', 'gear'];

const officeIcons = ['app-window', 'pencil-ruler', 'address-book', 'desktop', 'desktop-tower', 'clipboard', 'clipboard-text', 'receipt',
  'blueprint', 'paperclip', 'folder', 'folder-open', 'chart-line', 'calculator', 'archive', 'bookmark-simple', 'printer'];

const generalIcons = ['shopping-cart', 'cube-transparent', 'clock', 'alarm', 'cash-register', 'coffee',
  'dot-outline', 'dots-three-outline', 'folder-open', 'leaf', 'user', 'handshake'];

const iconCategories = new Map<string, string[]>([
  ['Werkzeug', toolIcons],
  ['Baustelle', constructionIcons],
  ['Fahrzeuge', vehicleIcons],
  ['Büro', officeIcons],
  ['Allgemein', generalIcons]
]);

const emits = defineEmits(['on-select', 'on-close']);

const openModal = (): void => {
  isVisible.value = true;
};

const closeModal = (): void => {
  emits('on-close');
  isVisible.value = false;
};

function onSelect(icon: string) {
  emits('on-select', icon);
  closeModal();
}

defineExpose({
  openModal,
  closeModal
});
</script>

<style scoped>

</style>