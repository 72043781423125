<template>
  <div v-if="isVisible" class="modal-container">
    <div class="modal-small-content w-modalWidth">

      <!-- Title-->
      <div class="p-8 mb-11 text-title">Offerte oder Rapport?</div>

      <div class="flex flex-row items-center h-auto">
        <base-button class="flex-1 m-8 p-8" @click="onSubproject">
          <phosphor-icon icon="clipboard-text" class="mr-5" />
          Rapport
        </base-button>
        <base-button class="flex-1 m-8 p-8" @click="onOffer">
          <phosphor-icon icon="handshake" class="mr-5" />
          Offerte
        </base-button>
      </div>

      <!-- Buttons -->
      <div class="absolute inset-x-0 bottom-0">
        <div class="flex">
          <base-button class="flex-1 m-8" look="secondary" @click="closeModal">
            Abbrechen
          </base-button>
        </div>
      </div>

    </div>
  </div>
</template>

<script setup lang="ts">

import BaseButton from '@/components/base/BaseButton.vue'
import { ref } from 'vue'
import PhosphorIcon from '@/components/base/PhosphorIcon.vue'

const emits = defineEmits(['on-invoice', 'on-offer']);

const isVisible = ref(false);

const openModal = (): void => {
  isVisible.value = true;
};

const closeModal = (): void => {
  isVisible.value = false;
};

async function onOffer() {
  emits('on-offer');
  isVisible.value = false;
}

async function onSubproject() {
  emits('on-invoice');
  isVisible.value = false;
}

defineExpose({
  openModal,
  closeModal
});

</script>

<style scoped>

</style>