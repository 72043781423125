<template>
  <div v-if="isVisible" class="modal-container" @click="closeModal">
   <div class="modal-giant-content p-0 md:p-8 flex flex-col" @click.stop>

      <!-- Title-->
      <div class="flex flex-row mb-8 items-center rounded-sm bg-grey-light">
        <div class="pr-2 whitespace-nowrap overflow-hidden text-ellipsis min-w-0">
          <div class="p-8 text-title">Position - {{chapter.name}}</div>
        </div>
        <button v-if="position"
          @click="openDeleteModal"
          :class="`flex items-center justify-center btn md:mr-0 mr-outerFrameBorder h-15 min-w-15 ml-auto btn-secondary`">
          <phosphor-icon icon="trash" class="text-center" />
        </button>
      </div>

      <!-- Main Content -->
      <div class="overflow-y-auto flex-grow" style="padding-bottom: 75px;">


        <base-container class="mb-betweenElements">
          <FormKit v-if="offer.chapters.length > 1" type="select" label="Phase" :options="chapterOptions" v-model="chapterId" />
        </base-container>


        <div class="flex flex-row space-x-0 h-fit">
          <button :class="`${single ? 'border-b-white hover:cursor-default' : 'text-grey-dark hover:text-primary'} !pl-outerFrameBorder transition-colors md:p-4 p-6 flex rounded-r-none rounded-b-none md:rounded-tl-md rounded-none w-full items-center bg-white border-1 border-grey-mid`" @click="setSingle(true)">
            <phosphor-icon icon="minus" look="regular" />
            <span class="h-full text-center ml-3">Einzelposition</span>
          </button>
          <button :class="`${single ? 'text-grey-dark hover:text-primary' : 'border-b-white hover:cursor-default'} !pl-outerFrameBorder transition-colors md:p-4 p-6 flex md:rounded-tr-md w-full items-center border-l-0 bg-white border-1 border-grey-mid`" @click="setSingle(false)">
            <phosphor-icon icon="list" look="regular" />
            <span class="h-full text-center ml-3">Mehrfachposition</span>
          </button>
        </div>
        <base-container class="mb-betweenElements !rounded-t-none !border-t-0">
          <div v-if="single" class="flex justify-between mb-betweenElements">
            <base-button look="secondary" type="icon" class="ml-auto h-fit rounded-lg" @click="openCategoryModal">
              <phosphor-icon icon="folder-open" />
              <span class="ml-5">{{ chapter.reportType ? (chapter.reportType == ReportType.WORK ? 'Pauschale Arbeit wählen' : 'Material wählen') : 'Vorlage wählen' }}</span>
            </base-button>
          </div>

          <category-card ref="categoryCard"
                         :existing="existingPosition"
                         :type="chapter.reportType"
                         :single="single"
                         @receive-description="receiveDescriptionFromCategoryCard">
          </category-card>

          <base-heading v-if="!single" type="h2" class=" pb-4 mt-5 !mb-0">Unterpositionen</base-heading>

          <entries-card ref="entriesCard"
                        :existing-entries="existingPosition?.entries ?? []"
                        :type="chapter.reportType"
                        :single="single"
                        @receive-entries="receiveEntries">
          </entries-card>
        </base-container>

        <categories-modal ref="categoryModal" :type="getChapter()?.reportType" @receive-modal-category="receiveCategory"></categories-modal>
        <confirmation-modal ref="confirmDeletionModal"
                            title="Rapportposition Löschen"
                            confirm-text="Löschen"
                            @on-confirm="onDelete">
          Soll die Rapportposition wirklich gelöscht werden?
        </confirmation-modal>


      </div>

      <!-- Buttons -->
      <div class="absolute inset-x-0 bottom-0 rounded-md bg-grey-light">
        <div class="flex justify-between">
          <base-button class="flex-1 m-8" look="secondary" @click="closeModal">
            Abbrechen
          </base-button>

          <base-button class="flex-1 m-8" @click="onSubmit">
            <spinner-small v-if="requesting" />
            <div v-else>{{submitText}}</div>
          </base-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">

import BaseButton from '@/components/base/BaseButton.vue'
import { onMounted, onUnmounted, ref, watch } from 'vue'
import { ReportType } from '@/model/Type'
import SpinnerSmall from '@/components/generel/SpinnerSmall.vue'
import CategoryCard from '@/components/workReportPosition/CategoryCard.vue'
import ConfirmationModal from '@/components/generel/ConfirmationModal.vue'
import BaseContainer from '@/components/base/BaseContainer.vue'
import PhosphorIcon from '@/components/base/PhosphorIcon.vue'
import CustomFields from '@/components/generel/CustomFields.vue'
import CategoriesModal from '@/components/workReportPosition/CategoriesModal.vue'
import EntriesCard from '@/components/workReportPosition/EntriesCard.vue'
import type { ReportPosition, ReportPositionRequest } from '@/model/ReportPosition'
import { DateConverter } from '@/client/DateConverter'
import type { ReportPositionEntry } from '@/model/ReportPositionEntry'
import { useToast } from 'vue-toast-notification'
import { API, PathSegment } from '@/client/axios'
import type Category from '@/model/Category'
import type { Chapter } from '@/model/Chapter'
import type { OfferPosition, OfferPositionRequest } from '@/model/OfferPosition'
import type { Offer } from '@/model/Offer'
import type { OfferPositionEntry } from '@/model/OfferPositionEntry'

const props = defineProps<{
  offer: Offer
  position: OfferPosition | undefined
  chapter: Chapter
}>();

const emits = defineEmits(['on-close', 'on-new-position', 'on-edited-position', 'on-delete-position']);

const isVisible = ref(false);
const requesting = ref(false);

const OFFER_POSITION_NAME = 'Offertenposition';

const reportPositionLoading = ref(true);
const existingPosition = ref<OfferPosition>();
const date = ref(DateConverter.getCurrentLocalDateISO());
const chapterOptions = ref<{label: string; value: string}[]>([]);
const chargeable = ref(true);
const chapterId = ref<string>(props.chapter.id);

const submitText = ref<string>();

const categoryCard = ref(CategoryCard);
const description = ref('');

const entriesCard = ref(EntriesCard);
const entries = ref<OfferPositionEntry[]>([]);

const customFields = ref<InstanceType<typeof CustomFields> | null>(null);
const confirmDeletionModal = ref(ConfirmationModal);

const $toast = useToast();

const single = ref(true);

function receiveDescriptionFromCategoryCard(text: string) {
  description.value = text;
}

function getDescription() {
  if (categoryCard.value) {
    categoryCard.value.emitDescription();
  }
}

function receiveEntries(emittedEntries: ReportPositionEntry[]) {
  entries.value = emittedEntries;
}

function getEntries() {
  if (entriesCard.value) {
    entriesCard.value.emitForms();
  }
}

function setSingle(value: boolean) {
  single.value = value;
}

async function onSubmit() {
  getDescription();
  getEntries();
  if (entries.value.length <= 0) {
    $toast.error('Kein Arbeiter hinzugefügt');
    return;
  }
  if (props.position) await onUpdate();
  else await onCreate();
}

async function onCreate() {
  if (requesting.value) return;
  requesting.value = true;
  const response = await API.createDataObject<OfferPosition, OfferPositionRequest>(
    PathSegment.OFFER_POSITIONS,
    {
      offerId: props.offer.id,
      description: description.value,
      chapterId: chapterId.value,
      entries: entries.value
    }, OFFER_POSITION_NAME);
  if (response) emits('on-new-position', response)
  closeModal();
  requesting.value = false;
}

async function onUpdate() {
  if (requesting.value) return;
  if (!props.position) {
    $toast.error('Aktuallisieren nicht möglich, keine Offerten ID vorhanden');
    console.error('Update failed: OfferPositionId not set');
    return;
  }

  requesting.value = true;
  const response = await API.updateDataObject<OfferPosition, OfferPositionRequest>(
    PathSegment.OFFER_POSITIONS,
    {
      id: props.position.id,
      offerId: props.offer.id,
      description: description.value,
      chapterId: chapterId.value,
      entries: entries.value,
    }, OFFER_POSITION_NAME);
  if (response) emits('on-edited-position', response)
  closeModal();
  requesting.value = false;
}

async function onDelete() {
  const resp = await API.deleteDataObject(PathSegment.OFFER_POSITIONS, props.position!.id, OFFER_POSITION_NAME);
  if (!resp) return;
  emits('on-delete-position', props.position!.id)
  closeModal();
}

const openDeleteModal = () => {
  if (confirmDeletionModal.value) {
    confirmDeletionModal.value.openModal();
  }
};

const categoryModal = ref(CategoriesModal);
const openCategoryModal = () => {
  if (categoryModal.value) {
    categoryModal.value.openModal();
  }
};

function receiveCategory(category: Category) {
  if (categoryCard.value) {
    categoryCard.value.setDescription(category.invoiceInscription);
  }
  if (entriesCard.value) {
    entriesCard.value.receiveCategory(category);
  }
}

function getChapter() {
  return props.offer.chapters.find(c => c.id == chapterId.value)
}

watch(() => props.position, newVal => {
  if (newVal) {
    // edit
    submitText.value = 'Speichern';
    existingPosition.value = props.position;
    single.value = newVal.entries.length == 1 && !newVal.entries[0].description;
    // loadReportPosition(newVal);
  } else {
    // create
    submitText.value = 'Erfassen';
    existingPosition.value = undefined;
    reportPositionLoading.value = false;
    date.value = DateConverter.getCurrentLocalDateISO();
  }
}, {
  immediate: true
});

const openModal = (): void => {
  isVisible.value = true;
};

const closeModal = (): void => {
  emits('on-close');
  requesting.value = false;
  isVisible.value = false;
};

onMounted(async () => {
  if (props.offer.chapters.length == 0) return;
  chapterOptions.value = [...props.offer.chapters.map((c => {return {label: `${c.index + 1}. ${c.name}`, value: c.id}}))];
});

onUnmounted(() => {
  document.body.style.overflow = "";
})

defineExpose({
  openModal
});


</script>

<style scoped>

</style>