<template>

  <div>
    <div class="flex flex-row">
      <div class="w-full">
        <FormKit type="textarea" auto-height label="Positionsbeschreibung*" v-model="descriptionText" />
      </div>
      <button class="pl-betweenElements" @click="openTranscriptionModal">
        <phosphor-icon icon="microphone" />
      </button>
    </div>

    <transcription-modal ref="transcriptionModalRef"
                         :existing-text="descriptionText"
                         @on-transcribed="onTranscribed" />
  </div>
</template>

<script setup lang="ts">
import { defineExpose, ref, watch } from 'vue'
import { ReportType } from '@/model/Type'
import { useToast } from 'vue-toast-notification'
import TranscriptionModal from '@/components/workReportPosition/TranscriptionModal.vue'
import type { ReportPosition } from '@/model/ReportPosition'
import PhosphorIcon from '@/components/base/PhosphorIcon.vue'
import type { OfferPosition } from '@/model/OfferPosition'

const props = defineProps<{
  existing: ReportPosition | OfferPosition | undefined;
  type: ReportType | undefined;
  single: boolean;
}>();

const descriptionText = ref('');

const showLatestDescriptions = ref(false);

const $toast = useToast();

const emits = defineEmits(['receive-description']);

watch(() => props.existing, newVal => {
  if (!newVal) return;
  descriptionText.value = newVal.description ?? '';
}, {
  immediate: true
});

const transcriptionModalRef = ref(TranscriptionModal);
const openTranscriptionModal = () => {
  if (transcriptionModalRef.value) {
    transcriptionModalRef.value.openModal();
  }
};

function onTranscribed(text: string) {
  if (!text || text == '') return;
  descriptionText.value = text;
}

// emitting selected categories to parent component
function emitDescription() {
  emits('receive-description', descriptionText.value);
}

function setDescription(text: string) {
  descriptionText.value = text;
}

defineExpose({
  emitDescription,
  setDescription
})

//endregion
</script>

<style scoped>

</style>