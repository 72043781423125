export default {
  global: {
    label: 'text-bodyMediumBold disabled:text-grey-mid',
    input: 'text-bodyMediumNormal focus-visible:outline-primary focus-visible:shadow-inner',
    help: 'text-bodySmallNormal text-grey-mid mt-2',
    placeholder: 'text-bodyMediumNormal text-grey-dark',
    messages: 'text-bodySmallNormal text-red mt-2',
    noFiles: 'text-bodySmallNormal',
    fileName: 'text-bodySmallNormal',
    fileRemove: 'text-bodySmallNormal text-primary underline'
  },
  "family:text": {
    outer: 'mb-betweenElements',
    input: 'w-full text-form rounded-sm border border-grey-mid bg-transparent px-formX py-formY disabled:border-grey-light disabled:bg-grey-light disabled:text-grey-mid',
  },
  "family:button": {
    wrapper: '',
    input: 'btn btn-primary btn-normal w-full sm:w-auto !text-bodyMediumBold'
  },
  "family:box": {
    wrapper: 'flex items-center',
    label: 'ml-4'
  },
  "select": {
    outer: 'mb-betweenElements',
    input: 'rounded-sm p-3 bg-white border border-grey-mid cursor-pointer hover:border-primary transition-colors',
  },
  "textarea": {
    outer: 'mb-betweenElements',
    input: 'w-full rounded-sm border border-grey-mid bg-transparent px-formX py-formY text-form'
  },
  "checkbox": {
    outer: 'mb-betweenElements hover:cursor-pointer'
  },
  "radio": {
    options: 'flex flex-row space-betweenElements',
    option: 'mr-14'
  }
}
