<template>

  <base-site title="Kunden" :show-button="KeycloakService.isManager()" button-icon="plus" @button-action="onAddCustomer">
    <base-container>

      <div v-if="showSearch" class="flex items-center bg-grey-mid rounded-lg overflow-hidden mb-betweenElements min-h-13">
        <span class="flex items-center px-3">
            <base-icon>search</base-icon>
        </span>
        <input type="text" class="w-full py-2 px-4 bg-grey-mid border-0 text-bodyLargeNormal focus:outline-none focus:ring-0" placeholder="Kundensuche" v-model="searchTerm" @input="onSearch">
        <span v-if="searchTerm" class="flex items-center px-3 hover:cursor-pointer">
            <base-icon @click="onClearSearch">clear</base-icon>
        </span>
      </div>

      <div v-if="addProjectVisible && customers.length == 0" class="flex flex-col items-center justify-center text-center h-full">
        <p class="text-bodyMediumBold align-middle text-grey-dark m-11">Keine Kunden gefunden</p>
        <base-button v-if="KeycloakService.isManager()" @click="onAddCustomer()">
          Neuer Kunde erstellen
        </base-button>
      </div>

      <pageable-content ref="pageableContent" 
                        :page-select-on-top="true"
                        @load-page="loadPage">
          <div v-for="(customer, index) in customers" :key="index"
              class="selectable flex flex-col px-8 py-5" :class="{'mb-betweenElements': !(customers.length - 1 == index)}"
              @click="onViewCustomer(customer.id)">
            <p class="text-bodyMediumBold pb-2 whitespace-nowrap overflow-hidden text-ellipsis min-w-0">
              {{Utils.getCustomerTitle(customer)}}
            </p>
            <p class="text-bodySmallNormal whitespace-nowrap overflow-hidden text-ellipsis min-w-0">
              {{customer.address.street}}, {{customer.address.zip}} {{customer.address.city}}
            </p>
          </div>
        </pageable-content>

    </base-container>
  </base-site>

</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import type Customer from '@/model/Customer'
import BaseSite from '@/components/base/BaseSite.vue'
import BaseContainer from '@/components/base/BaseContainer.vue'
import { routeNames } from '@/router'
import { useRoute, useRouter } from 'vue-router'
import BaseButton from '@/components/base/BaseButton.vue'
import { Utils } from '../../client/utils'
import BaseIcon from '@/components/base/BaseIcon.vue'
import { API, PathSegment } from '@/client/axios'
import { TenantCompany } from '@/stores/TenantCompany'
import { KeycloakService } from '@/service/keycloakService'
import type { Page } from '@/model/Page'
import PageableContent from '@/components/generel/PageableContent.vue'

const router = useRouter();
const route = useRoute();
const customers = ref<Customer[]>([]);

const showSearch = ref(false);
const searchTerm = ref(!route.query.searchTerm ? '' : route.query.searchTerm as string);

const pageableContent = ref(PageableContent);

const addProjectVisible = ref(false);

function onAddCustomer() {
  router.push({name: routeNames.CREATE_CUSTOMER});
}

function onViewCustomer(id: string) {
  router.push({name: routeNames.CUSTOMER, params: {id: id}});
}

async function onSearch() {
  if (!pageableContent.value) {
    console.error("pageable content not set when searching");
    return;
  }

  const term = searchTerm.value;
  await Utils.delay(600);
  if (term != searchTerm.value) return;
  pageableContent.value.resetValues();
  const data = await loadCustomers(pageableContent.value.getPageSize(), pageableContent.value.getOffset(), true);
  if (term != searchTerm.value) return;
  customers.value = data;
  router.replace({
    path: route.path,
    query: {
      ...route.query,
      searchTerm: term
    }
  });
}

function onClearSearch() {
  searchTerm.value = '';
  pageableContent.value.setLoading();
  pageableContent.value.loadPage();
  router.replace({
    path: route.path,
    query: {
      ...route.query,
      searchTerm: ''
    }
  });
}

async function loadCustomers(pageSize: number, offset: number, setLoading: boolean = false) {
  if (!pageableContent.value) {
    console.error("pageable content not set when loading page");
    return [];
  }
  
  addProjectVisible.value = false;
  if (setLoading) pageableContent.value.setLoading();

  const response = await API.wrapGet<Page<Customer>>(
    API.getWithParameters(
      PathSegment.COMPANIES, 
      TenantCompany.getCompany().id, 
      PathSegment.CUSTOMERS, 
      new Map<string,string>([
        ['searchTerm', searchTerm.value],
        ['pageSize', `${pageSize}`],
        ['offset', `${offset}`]
      ])), 
    'Suchresultat');
  if (!response) return [];

  if (searchTerm.value || response.page.length > 0) showSearch.value = true;
  addProjectVisible.value = true;

  pageableContent.value.pageLoaded(response.totalPages);
  return response.page;
}

async function loadPage(pageSize: number, offset: number) {
  const page = await loadCustomers(pageSize, offset);
  window.scrollTo(0,0);
  customers.value = page;
}

onMounted(() => {
  if (!pageableContent.value) {
    console.error("pageable content not set on Mounted");
    return;
  }
  pageableContent.value.setLoading();
  pageableContent.value.loadPage();
});

</script>

<style scoped>

</style>