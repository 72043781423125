<template>
  <div>

    <button class="flex flex-row mt-betweenElements items-center hover:text-primary transition-colors" @click="toggleContent">
      <phosphor-icon :icon="contentVisible ? 'caret-up' : 'caret-down'" size="text-iconSizeSmall" class="mr-4" />
      <span class="text-bodyMediumBold">{{ label }}</span>
    </button>

    <transition
      name="dropdown"
      @before-enter="beforeEnter"
      @enter="enter"
      @after-enter="afterEnter"
      @before-leave="beforeLeave"
      @leave="leave"
      @after-leave="afterLeave">

      <div v-if="contentVisible" ref="content">
        <slot></slot>
      </div>

    </transition>

  </div>
</template>

<script setup lang="ts">

import PhosphorIcon from '@/components/base/PhosphorIcon.vue'
import { onMounted, ref } from 'vue'

const props = withDefaults(defineProps<{
  label: string;
  visible?: boolean;
}>(), {
  visible: false
});

const contentVisible = ref(false);

function toggleContent() {
  contentVisible.value = !contentVisible.value
}

function beforeEnter(el: any) {
  el.style.height = '0';
  el.style.opacity = '0';
}

function enter(el: any) {
  el.style.transition = 'height 0.3s ease, opacity 0.3s ease';
  el.style.height = el.scrollHeight + 'px';
  el.style.opacity = '1';
}

function afterEnter(el: any) {
  el.style.height = 'auto';
}

function beforeLeave (el: any) {
  el.style.height = el.scrollHeight + 'px';
  el.style.opacity = '1';
}

function leave(el: any) {
  el.style.transition = 'height 0.3s ease, opacity 0.3s ease';
  el.style.height = '0';
  el.style.opacity = '0';
}

function afterLeave(el: any) {
  el.style.height = '0';
  el.style.opacity = '0';
}

onMounted(() => {
  contentVisible.value = props.visible;
})

</script>

<style scoped>

</style>