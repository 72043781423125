<template>
  <div>

    <dropdown :label="title">
      <div v-if="chapters.length == 0" class="text-grey-dark text-bodyMediumBold p-5">Keine Phase vorhanden</div>
      <div v-for="chapter of chapters" :key="chapter.index" class="flex flex-row p-5 border border-grey-mid rounded mt-5 items-center">
        <span>{{ chapter.index + 1 }}. {{ chapter.name }}</span>
        <div class="ml-auto w-15">
          <button class="hover:text-primary transition-colors pl-10" @click="chapterMenuVisible[chapter.index] = true">
            <phosphor-icon icon="dots-three-vertical" />
          </button>
          <chapter-menu v-if="chapterMenuVisible[chapter.index]"
                        @on-click-outside="chapterMenuVisible[chapter.index] = false"
                        @on-edit="onEditChapter(chapter.index)"
                        @on-move-up="onMoveChapterUp(chapter.index)"
                        @on-move-down="onMoveChapterDown(chapter.index)"
                        @on-delete="onDeleteChapter(chapter.index)" />
        </div>
      </div>
      <base-button look="secondary" class="mt-5 w-full rounded-sm p-5" @click="openChapterNameModal">
        <phosphor-icon icon="plus" />
      </base-button>
    </dropdown>

    <confirmation-modal ref="chapterNameModal"
                        :title="chapterEditIndex == -1 ? 'Phase hinzufügen' : 'Phase bearbeiten'"
                        :confirm-text="chapterEditIndex == -1 ? 'Hinzufügen' : 'Speichern'"
                        margin-bottom-title="mb-4"
                        @on-confirm="() => chapterEditIndex == -1 ? onAddChapter() : editChapter()"
                        @close-action="resetChapterValues(); chapterEditIndex = -1">
      <div class="flex items-center justify-between gap-5">
        <div class="flex-1">
          <FormKit type="text" label="Name*" validation="required" v-model="chapterName" class="w-full" />
        </div>
        <button @click="openIconSelectorModal" class="p-5 border selectable">
          <phosphor-icon :icon="chapterIcon ?? 'prohibit'" />
        </button>
      </div>
      <FormKit v-if="!TenantSettings.isTransport()" type="select" label="Typ" :options="[{label: '', value: undefined}, {label: 'Arbeit', value: ReportType.WORK}, {label: 'Material', value: ReportType.MATERIAL}]" v-model="chapterType" />
    </confirmation-modal>

    <icon-selector ref="iconSelector" @on-select="onIconSelect" />

  </div>
</template>

<script setup lang="ts">
import { ReportType } from '@/model/Type'
import { useRoute } from 'vue-router'
import { ref, watch } from 'vue'
import ConfirmationModal from '@/components/generel/ConfirmationModal.vue'
import BaseButton from '@/components/base/BaseButton.vue'
import PhosphorIcon from '@/components/base/PhosphorIcon.vue'
import type { ChapterRequest } from '@/model/Chapter'
import ChapterMenu from '@/components/invoice/ChapterMenu.vue'
import { useToast } from 'vue-toast-notification'
import { UrlData } from '@/client/UrlData'
import Dropdown from '@/components/generel/Dropdown.vue'
import { TenantSettings } from '@/stores/TenantSettings'
import IconSelector from '@/components/generel/IconSelector.vue'

const props = defineProps<{
  title: string
  initialChapters: ChapterRequest[]
}>();

const route = useRoute();

const chapterName = ref('');
const chapterType = ref<ReportType | undefined>();
const chapterIcon = ref<string | undefined>();
const chapterEditIndex = ref(-1);
const chapters = ref<ChapterRequest[]>(route.query.chapters ? UrlData.toData<ChapterRequest[]>(route.query.chapters as string) : []);
const chapterNameModal = ref(ConfirmationModal);
const chapterMenuVisible = ref<boolean[]>([]);

const iconSelector = ref(IconSelector);

const $toast = useToast();

const standardIcons = new Map<ReportType | undefined, string>([
  [ReportType.WORK, 'hammer'],
  [ReportType.MATERIAL, 'package'],
  [undefined, TenantSettings.isTransport() ? 'truck-trailer' : 'hard-hat']]);

function onAddChapter() {
  if (!chapterName.value) {
    $toast.error('Kein Phasenname angegeben');
    return;
  }
  if (chapters.value.find(c => c.name === chapterName.value)) {
    $toast.error('Phasenname bereits vergeben');
    return;
  }
  chapters.value = [...chapters.value, {name: chapterName.value, index: chapters.value.length, reportType: chapterType.value, icon: chapterIcon.value ?? standardIcons.get(chapterType.value)}];
  resetChapterValues();
  chapterMenuVisible.value = [...chapters.value.map(() => false)];
}

function onEditChapter(index: number) {
  chapterMenuVisible.value[index] = false;
  chapterEditIndex.value = index;
  chapterName.value = chapters.value[index].name;
  chapterType.value = TenantSettings.isTransport() ? ReportType.MATERIAL : chapters.value[index].reportType;
  chapterIcon.value = chapters.value[index].icon;
  openChapterNameModal();
}

function editChapter() {
  if (!chapterName.value) {
    $toast.error('Kein Phasenname angegeben');
    return;
  }
  const existinChapter = chapters.value.find(c => c.name === chapterName.value);
  if (existinChapter && existinChapter.index != chapterEditIndex.value) {
    $toast.error('Phasenname bereits vergeben');
    return;
  }
  chapters.value[chapterEditIndex.value].name = chapterName.value;
  chapters.value[chapterEditIndex.value].reportType = chapterType.value;
  chapters.value[chapterEditIndex.value].icon = chapterIcon.value;
  resetChapterValues();
  chapterEditIndex.value = -1;
}

function onMoveChapterUp(index: number) {
  chapterMenuVisible.value[index] = false;
  if (index <= 0 || index >= chapters.value.length) {
    console.warn("Cannot move the object up further.");
    return;
  }

  const temp = chapters.value[index];
  chapters.value[index] = chapters.value[index - 1];
  chapters.value[index - 1] = temp;

  chapters.value[index].index = index;
  chapters.value[index - 1].index = index - 1;
}

function onMoveChapterDown(index: number) {
  chapterMenuVisible.value[index] = false;
  if (index < 0 || index >= chapters.value.length - 1) {
    console.warn("Cannot move the object down further.");
    return;
  }

  const temp = chapters.value[index];
  chapters.value[index] = chapters.value[index + 1];
  chapters.value[index + 1] = temp;

  chapters.value[index].index = index;
  chapters.value[index + 1].index = index + 1;
}

function onDeleteChapter(index: number) {
  chapterMenuVisible.value[index] = false;
  if (index < 0 || index >= chapters.value.length) {
    console.warn("Invalid index. Cannot remove the chapter.");
    return;
  }

  chapters.value.splice(index, 1);

  for (let i = index; i < chapters.value.length; i++) {
    chapters.value[i].index = i;
  }
}

function resetChapterValues() {
  chapterName.value = '';
  chapterType.value = undefined;
  chapterIcon.value = undefined;
}

function openChapterNameModal() {
  if (!chapterNameModal.value) return;
  chapterNameModal.value.openModal();
}

function onIconSelect(icon: string) {
  chapterIcon.value = icon;
}

function openIconSelectorModal() {
  if (!iconSelector.value) return;
  iconSelector.value.openModal();
}

watch(() => props.initialChapters, newVal => {
  if (!newVal) return;
  chapters.value = newVal;
}, {
  immediate: true
});

function getChapters(): ChapterRequest[] {
  return chapters.value;
}

defineExpose({
  getChapters
})

</script>

<style scoped>

</style>
