<template>
  <base-site :title="TenantSettings.isProjectOriented() ? 'Alle Projekte' : 'Kunden'"
             :show-button="KeycloakService.isManager()"
             button-icon="plus"
             @button-action="TenantSettings.isProjectOriented() ? onAddProject() : onAddCustomer()">

    <base-container>

      <div v-if="showSearch" class="flex flex-col sm:flex-row sm:items-center">
        <!-- Search Field Container -->
        <div class="flex items-center bg-grey-mid rounded-lg overflow-hidden mb-betweenElements min-h-13 flex-grow">
          <span class="flex items-center px-3">
            <base-icon>search</base-icon>
          </span>
                <input type="text" class="w-full py-2 px-4 bg-grey-mid border-0 text-bodyLargeNormal focus:outline-none focus:ring-0" :placeholder="TenantSettings.isProjectOriented() ? 'Projektsuche' : 'Kundensuche'" v-model="search" @input="onSearch">
                <span v-if="search" class="flex items-center px-3 hover:cursor-pointer">
            <base-icon @click="onClearSearch">clear</base-icon>
          </span>
        </div>

        <!-- Select Field Container -->
        <div class="sm:ml-betweenElements flex-shrink-0">
          <FormKit type="select" :options="[...Utils.toSelectOptions(Type.getAllStatuses(), 'Alle'), ...(TenantSettings.isTransport() ? [] : offerSelectOptions())]" v-model="statusFilter" />
        </div>
      </div>

      <div v-if="addProjectVisible && projects.length == 0" class="flex flex-col items-center justify-center text-center h-full">
        <p class="text-bodyMediumBold align-middle text-grey-dark m-11">Keine {{ TenantSettings.isProjectOriented() ? 'Projekte' : 'Kunden' }} gefunden</p>
        <base-button v-if="KeycloakService.isManager()" @click="onAddProject()">
          {{ TenantSettings.isProjectOriented() ? 'Neues Projekt erstellen' : 'Neuer Kunde erstellen' }}
        </base-button>
      </div>

      <pageable-content ref="pageableContent" 
                      :page-select-on-top="true"
                      @load-page="loadPage">
        <div v-for="(project, index) of projects" :key="project.id"
              class="space-x-betweenElements border border-grey-mid rounded-sm px-8 py-5 hover:shadow-inner transition-all ease-in-out flex justify-between items-center group hover:border-primary cursor-pointer container"
              :class="{'mb-betweenElements': projects.length - 1 != index}"
              @click="onViewProject(project.id)">
          <div class="flex flex-col w-full">
            <div class="flex items-center space-x-[4px] min-w-0 pb-2">
              <div class="text-bodySmallNormal whitespace-nowrap overflow-hidden text-ellipsis flex-1 min-w-0">
                {{ TenantSettings.isCustomerOriented() ? Utils.getCustomerOneLineAddress(project.customer) : Utils.getCustomerTitle(project.customer) }}
              </div>
              <p class="text-bodySmallBold flex-shrink-0 ml-2" :style="{ color: project.statusId ? Type.getStatus(project.statusId).displayColor : '#ffffff' }">
                {{ project.statusId ? Type.getStatus(project.statusId).name : '' }}
              </p>
            </div>
            <p class="text-bodyMediumBold group-hover:text-primary whitespace-nowrap overflow-hidden text-ellipsis min-w-0">
              {{ project.name }}
            </p>
          </div>
        </div>
      </pageable-content>

    </base-container>

  </base-site>
</template>

<script setup lang="ts">

import { KeycloakService } from '@/service/keycloakService'
import { OfferStatus, Type } from '@/model/Type'
import BaseContainer from '@/components/base/BaseContainer.vue'
import BaseButton from '@/components/base/BaseButton.vue'
import { useRoute, useRouter } from 'vue-router'
import { nextTick, ref, watch } from 'vue'
import type Project from '@/model/Project'
import { useToast } from 'vue-toast-notification'
import BaseSite from '@/components/base/BaseSite.vue'
import { API, PathSegment } from '@/client/axios'
import { Utils } from '@/client/utils'
import BaseIcon from '@/components/base/BaseIcon.vue'
import { routeNames } from '@/router'
import { TenantSettings } from '@/stores/TenantSettings'
import type { Page } from '@/model/Page'
import PageableContent from '@/components/generel/PageableContent.vue'

const router = useRouter();
const route = useRoute();

const projects = ref<Project[]>([]);

const showSearch = ref(false);
const search = ref('');
const statusFilter = ref(!route.query.statusFilter ? '' : route.query.statusFilter as string);

const pageableContent = ref(PageableContent);

let inited = false;
const addProjectVisible = ref(false);

const $toast = useToast();

function offerSelectOptions() {
  return [
    {label: 'Offerte: Offen', value: OfferStatus.OPEN},
    {label: 'Offerte: Akzeptiert', value: OfferStatus.ACCEPTED},
    {label: 'Offerte: Abgelehnt', value: OfferStatus.REJECTED},
  ]
}

async function onViewProject(id: string) {
  await router.push({name: routeNames.PROJECT, params: {id: id}});
}

async function onAddProject() {
  await router.push({name: TenantSettings.isProjectOriented() ? routeNames.CREATE_PROJECT : routeNames.CREATE_CUSTOMER});
}

async function onAddCustomer() {
  await router.push({name: routeNames.CREATE_CUSTOMER});
}

async function onSearch() {
  if (!pageableContent.value) {
    console.error("pageable content not set when searching");
    return;
  }
  const term = search.value;
  await Utils.delay(600);
  if (term != search.value) return;

  const response = await loadProjects(pageableContent.value.getPageSize(), pageableContent.value.getOffset(), true); 
  if (term != search.value) return;
  projects.value = response;
}

watch(() => statusFilter.value, async newVal => {
  if (!pageableContent.value) {
    console.error("pageable content not set when clear search");
    return;
  }

  if (!newVal) newVal = '';
  await nextTick();
  pageableContent.value.setLoading();
  if (inited) pageableContent.value.resetValues();

  router.replace({
    path: route.path,
    query: {
      ...route.query,
      statusFilter: statusFilter.value
    }
  });

  // Content initialized here because it is called anyway at component startup
  pageableContent.value.loadPage();
}, {
  immediate: true
});

function onClearSearch() {
  if (!pageableContent.value) {
    console.error("pageable content not set when clear search");
    return;
  }
  search.value = '';
  pageableContent.value.setLoading();
  pageableContent.value.loadPage();
}

async function loadProjects(pageSize: number, offset: number, setLoading = false) {
  if (!pageableContent.value) {
    console.error("pageable content not set when loading page");
    return [];
  }

  addProjectVisible.value = false;
  if (setLoading) pageableContent.value.setLoading();

  const response = await API.wrapGet<Page<Project>>(
    API.getWithParameters(
      PathSegment.PROJECTS, 
      new Map<string,string>([
        ['includeCustomer', 'true'], 
        ['searchTerm', search.value],
        ['statusId', statusFilter.value],
        ['byOffer', `${statusFilter.value == OfferStatus.OPEN || statusFilter.value == OfferStatus.ACCEPTED || statusFilter.value == OfferStatus.REJECTED}`],
        ['pageSize', `${pageSize}`],
        ['offset', `${offset}`]
      ])),
      'Projekte'
  );

  if (!response) return [];
  if (search.value || response.page.length > 0) showSearch.value = true;

  inited = true;
  addProjectVisible.value = true;
  pageableContent.value.pageLoaded(response.totalPages);
  return response.page;
}

async function loadPage(pageSize: number, offset: number) {
  const response = await loadProjects(pageSize, offset);
  projects.value = response;
}

</script>

<style scoped>

</style>