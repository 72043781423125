<template>
  <div v-if="isVisible" class="modal-container" @click="closeModal">
    <div class="modal-tall-content flex flex-col" @click.stop>

      <!-- Title-->
      <div class="p-8 text-title">PDF-Formatierung</div>

      <!-- Main Content -->
      <div class="px-8 overflow-y-auto flex-grow" style="padding-bottom: 75px;">

        <FormKit v-if="invoice && invoice.chapters && invoice.chapters.length > 0" type="select" :classes="{outer: 'ml-auto max-w-parent !mb-2'}" v-model="chapterId" 
          :options="[{label: 'Alle Phasen', value: ChapterDefault.ALL}, ...invoice.chapters.map(c => {return {label: `${c.index + 1}. ${c.name}`, value: c.id}})]" />
        <FormKit v-if="offer && offer.chapters && offer.chapters.length > 0" type="select" :classes="{outer: 'ml-auto max-w-parent !mb-2'}" v-model="chapterId" 
          :options="[{label: 'Alle Phasen', value: ChapterDefault.ALL}, ...offer.chapters.map(c => {return {label: `${c.index + 1}. ${c.name}`, value: c.id}})]" />
        <div class="border border-grey-mid rounded p-8 mb-betweenElements">
          <div class="text-bodyMediumBold mb-betweenElements">{{ TenantSettings.isTransport() ? 'Transporte-Tabelle' : 'Phasen-Tabelle' }}</div>
          <FormKit type="checkbox" name="pageBreakAfterWork" id="pageBreakAfterWork" label="Tabelle auf neuer Seite" :disabled="generating" v-model="pageBreakBeforeReportTable" @input="onChange"/>
          <div class="flex md:flex-row flex-col">
            <div class="md:mr-betweenElements"><FormKit type="number" number label="Abstand vor Tabelle" :disabled="generating" v-model="marginBeforeReportTable" @input="onChange"/></div>
            <FormKit type="number" number label="Abstand nach Tabelle" :disabled="generating" v-model="marginAfterReportTable" @input="onChange"/>
          </div>
        </div>
        <div class="border border-grey-mid rounded p-8 mb-betweenElements">
          <div class="text-bodyMediumBold mb-betweenElements">Gesamtbetrags-Tabelle</div>
          <FormKit type="checkbox" name="pageBreakAfterWork" id="pageBreakAfterWork" label="Tabelle auf neuer Seite" :disabled="generating" v-model="pageBreakBeforeTotal" @input="onChange"/>
          <div class="flex md:flex-row flex-col">
            <div class="md:mr-betweenElements"><FormKit type="number" number label="Abstand vor Tabelle" :disabled="generating" v-model="marginBeforeTotal" @input="onChange"/></div>
            <FormKit type="number" number label="Abstand nach Tabelle" :disabled="generating" v-model="marginAfterTotal" @input="onChange"/>
          </div>
        </div>
        <FormKit type="number" number label="Tabellen-Schriftgrösse*" :disabled="generating" v-model="tableFontSize" @input="onChange"/>
        <FormKit v-if="isInvoice" type="checkbox" label="Betrag in QR-Rechnung aufführen" :disabled="generating" v-model="amountPresentOnQrBill" @input="onChange"/>
        <FormKit v-if="isInvoice" type="checkbox" label="Totalbetrag und QR-Rechnung auf der selben Seite" :disabled="generating" v-model="totalAndQrBillOnSameSite" @input="onChange"/>
        <FormKit v-if="isInvoice" type="checkbox" label="Kompakte Rechnung (Beta)" :disabled="generating" v-model="compactBill" @input="onChange"/>
      </div>

      <!-- Buttons -->
      <div class="absolute inset-x-0 bottom-0 rounded-md bg-grey-light">
        <div class="flex justify-between">
          <template v-if="!changed && pdf">
            <base-button type="icon" class="flex-1 m-8" look="secondary" @click="closeModal">
              Abbrechen
            </base-button>
            <base-button type="icon" class="flex-1 m-8" @click="onOpen">
              <base-icon class="mr-2">open_in_new</base-icon>
              <span class="hidden md:block">Öffnen</span>
            </base-button>
            <base-button type="icon" class="flex-1 m-8" @click="onDownload">
              <base-icon class="mr-2">download</base-icon>
              <span class="hidden md:block">Herunterladen</span>
            </base-button>
          </template>
          <template v-else>
            <base-button type="icon" class="flex-1 m-8" look="secondary" @click="closeModal">
              Abbrechen
            </base-button>

            <base-button type="icon" v-if="!pdf" class="flex-1 m-8" @click="onConfirm">
              <spinner-small v-if="generating" />
              <div v-else>Generieren</div>
            </base-button>
          </template>
        </div>
      </div>
    </div>
  </div>

</template>

<script setup lang="ts">
import BaseButton from '@/components/base/BaseButton.vue'
import { onMounted, ref, watch } from 'vue'
import SpinnerSmall from '@/components/generel/SpinnerSmall.vue'
import { PdfService } from '@/service/PdfService'
import { API, PathSegment } from '@/client/axios'
import type InvoiceInfo from '@/model/InvoiceInfo'
import { ResponseKey } from '@/model/ResponseWrapper'
import { Utils } from '@/client/utils'
import type { Invoice } from '@/model/Invoice'
import BaseIcon from '@/components/base/BaseIcon.vue'
import type OfferInfo from '@/model/OfferInfo'
import { OfferPdfService } from '@/service/OfferPdfService'
import type { Offer } from '@/model/Offer'
import { TenantSettings } from '@/stores/TenantSettings'
import { useToast } from 'vue-toast-notification'
import type { PdfFormatInfo, PdfTableFormat } from '@/model/PdfFormatInfo'
import { ChapterDefault } from '@/model/Chapter'

const props = defineProps<{
  isInvoice: boolean
  invoice: Invoice | undefined
  offer: Offer | undefined
}>();

const isVisible = ref(false);
const generating = ref(false);
const changed = ref(true);

// forms
const amountPresentOnQrBill = ref(false);
const marginBeforeReportTable = ref(10);
const marginAfterReportTable = ref(20);
const pageBreakBeforeReportTable = ref(false);
const marginBeforeTotal = ref(10);
const marginAfterTotal = ref(0);
const pageBreakBeforeTotal = ref(false);
const totalAndQrBillOnSameSite = ref(false);
const compactBill = ref(false);
const tableFontSize = ref(9);
const pdf = ref<undefined | {pdf: any, name: string}>(undefined);

const chapterId = ref<string>(ChapterDefault.ALL);
const pdfTableFormats = ref<Map<string, PdfTableFormat>>(new Map());

const $toast = useToast();

const emits = defineEmits(['on-close']);

const openModal = (): void => {
  isVisible.value = true;
};

const closeModal = (): void => {
  pdf.value = undefined;
  generating.value = false;
  emits('on-close');
  changed.value = true;
  isVisible.value = false;
};

watch(() => props.invoice, newVal => {
  if (!newVal) return;
  amountPresentOnQrBill.value = (newVal.skonto ?? 0) <= 0;
  if (!newVal.chapters || newVal.chapters.length == 0) chapterId.value = ChapterDefault.NONE;
  else {
    for (const chapter of newVal.chapters) pdfTableFormats.value.set(chapter.id, getDefaultTableFormat())
  }
}, {
  immediate: true
});

watch(() => props.offer, newVal => {
  if (!newVal) return;
  if (!newVal.chapters || newVal.chapters.length == 0) chapterId.value = ChapterDefault.NONE;
  else {
    for (const chapter of newVal.chapters) pdfTableFormats.value.set(chapter.id, getDefaultTableFormat())
  }
}, {
  immediate: true
});

async function onConfirm() {
  if (props.invoice) await generateInvoice();
  else if (props.offer) await generateOffer();
  else console.error('No invoice or offer given');
}

async function generateInvoice() {
  if (generating.value || !props.invoice) return;
  generating.value = true;
  changed.value = false;
  if (!props.invoice) return;
  const response = await getInvoiceInfo(props.invoice.id);
  if (!response) {
    generating.value = false;
    return;
  }

  setPdfTableFormat(chapterId.value);

  pdf.value = await PdfService.generateInvoice(response, {
    jobTables: pdfTableFormats.value,
    totalsTable: {
      pageBreakBefore: pageBreakBeforeTotal.value,
      marginBefore: marginBeforeTotal.value,
      marginAfter: marginAfterTotal.value,
    },
    tableFontSize: tableFontSize.value,
    totalAndQrBillOnSameSite: totalAndQrBillOnSameSite.value,
    compactBill: compactBill.value
  });
  generating.value = false;
}

async function generateOffer() {
  if (generating.value || !props.offer) return;
  generating.value = true;
  changed.value = false;
  if (!props.offer) return;
  const response = await getOfferInfo(props.offer.id);
  if (!response) {
    generating.value = false;
    return;
  }

  setPdfTableFormat(chapterId.value);

  pdf.value = await OfferPdfService.generateOffer(response, {
    jobTables: pdfTableFormats.value,
    totalsTable: {
      pageBreakBefore: pageBreakBeforeTotal.value,
      marginBefore: marginBeforeTotal.value,
      marginAfter: marginAfterTotal.value,
    },
    tableFontSize: tableFontSize.value,
    totalAndQrBillOnSameSite: totalAndQrBillOnSameSite.value,
    compactBill: compactBill.value
  });
  generating.value = false;
}

async function onOpen() {
  if (!pdf.value) return;

  // hack because .open() can throw an un-catchable error
  let opened = false;
  await pdf.value.pdf.getBuffer((buffer: any) => {
    if (buffer) {
      pdf.value!.pdf.open();
      opened = true;
    }
  });

  await sleep(1500);

  if (!opened) {
    console.error("PDF generation failed.");
    $toast.warning('Tabellendarstellung fehlgeschlagen. Schrift verkleinern', { duration: 20000 });
  }
}

function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

async function onDownload() {
  if (!pdf.value) return;

  // hack because .download() can throw an un-catchable error
  let opened = false;
  await pdf.value.pdf.getBuffer((buffer: any) => {
    if (buffer) {
      pdf.value!.pdf.download(pdf.value!.name);
      opened = true;
    }
  });

  await sleep(1500);

  if (opened) {
    closeModal();
  } else {
    console.error("PDF generation failed.");
    $toast.warning('Tabellendarstellung fehlgeschlagen. Schrift verkleinern', { duration: 20000 });
  }
}

async function getInvoiceInfo(invoiceId: string) {
  try {
    const response = await API.getWithParameters<InvoiceInfo>(PathSegment.INVOICES, invoiceId, PathSegment.INVOICE_INFO, new Map<string,string>([['qrAmountPresent', `${amountPresentOnQrBill.value}`]]));
    if (response.key == ResponseKey.OK) {
      return response.data;
    } else {
      API.logApiError('Rechnugnsinformation', 'geladen', response.message, response.key);
    }
  } catch (error) {
    API.logApiError('Rechnugnsinformation', 'geladen', Utils.getError(error));
  }
}

async function getOfferInfo(offerId: string) {
  try {
    const response = await API.get<OfferInfo>(PathSegment.OFFERS, offerId, PathSegment.OFFER_INFO);
    if (response.key == ResponseKey.OK) {
      return response.data;
    } else {
      API.logApiError('Offerteininformation', 'geladen', response.message, response.key);
    }
  } catch (error) {
    API.logApiError('Offerteininformation', 'geladen', Utils.getError(error));
  }
}

function onChange() {
  changed.value = true;
  pdf.value = undefined;
}

watch(() => chapterId.value, (newVal, oldVal) => {
  if (!newVal || !oldVal) return;

  setPdfTableFormat(oldVal);

  if (pdfTableFormats.value.has(newVal)) {
    const format = pdfTableFormats.value.get(newVal);
    if (!format) return;

    pageBreakBeforeReportTable.value = format.pageBreakBefore;
    marginBeforeReportTable.value = format.marginBefore;
    marginAfterReportTable.value = format.marginAfter;
  } else {
    pageBreakBeforeReportTable.value = false;
    marginBeforeReportTable.value = 0;
    marginAfterReportTable.value = 0;
  }
}, {
  immediate: true
});

function setPdfTableFormat(key: string) {
  if (key == ChapterDefault.ALL && props.invoice?.chapters) {
    for (const chapter of props.invoice?.chapters) setPdfTableFormat(chapter.id);
  } else {
    pdfTableFormats.value.set(key, {
      pageBreakBefore: pageBreakBeforeReportTable.value,
      marginBefore: marginBeforeReportTable.value,
      marginAfter: marginAfterReportTable.value,
    });
  }
}

function getDefaultTableFormat(): PdfTableFormat {
  return {
    pageBreakBefore: false,
    marginBefore: 0,
    marginAfter: 30
  }
}

onMounted(() => {

})

defineExpose({
  openModal,
  closeModal
});
</script>

<style scoped>

</style>