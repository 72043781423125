import type { ReportType } from '@/model/Type'

export interface Chapter {
  id: string,
  name: string,
  index: number,
  icon?: string,
  reportType?: ReportType
}

export interface ChapterRequest {
  id?: string,
  name: string,
  index: number,
  icon?: string,
  reportType?: ReportType
}

export enum ChapterDefault {
  ALL = "all",
  NONE = "none"
}