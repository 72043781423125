<template>
  <div class="flex flex-row transition-all py-3 px-7 border-t border-l border-r border-grey-mid rounded-t-sm cursor-pointer hover:border-primary hover:shadow-inner hover:text-primary" :class="{'border-b rounded-b-sm': !extended, 'mt-betweenElements': index != 0, 'border-primary': extended}"
       @click="emits('on-extend', index);">
    <div>{{ description ? `${description}` : 'Neu' }} {{ `  -  ${quantity} ${unitId ? Type.getUnit(unitId).abbreviation() : ''}` }}</div>
    <base-icon v-if="deleteEnabled" @click.stop class="ml-auto cursor-pointer text-primary" @click="emits('on-delete', index);">delete</base-icon>
  </div>
  <div :class="`${extended ? 'block' : 'hidden'}`" class="rounded-b-sm border border-b-primary border-l-primary border-r-primary border-t-grey-mid p-11">

    <entry-form :existing="existing"
                :presets="presets"
                :preset-options="presetOptions"
                :type="type"
                :single="false"
                @on-form-update="onFormUpdate"/>

  </div>

</template>

<script setup lang="ts">

import { ref, watch } from 'vue'
import type WorkerPreset from '@/model/WorkerPreset'
import { ReportType, Type } from '../../model/Type'
import type { ReportPositionEntry } from '@/model/ReportPositionEntry'
import EntryForm from '@/components/workReportPosition/EntryForm.vue'

const props = defineProps<{
  index: number,
  existing: ReportPositionEntry,
  presets: WorkerPreset[],
  presetOptions: {value: string, label: string}[],
  type: ReportType | undefined,
  extended: boolean,
  deleteEnabled: boolean
}>();

const unitId = ref('');
const description = ref('');
const quantity = ref(0.0);

const emits = defineEmits(['update-worker', 'on-extend', 'on-delete']);

function onFormUpdate(entry: ReportPositionEntry) {
  emits('update-worker', props.index, entry);
  updateTitle(entry);
}

watch(() => props.existing, newVal => {
  if (!newVal) return;
  updateTitle(newVal);
  emits('update-worker', props.index, newVal);
}, {
  immediate: true
});

function updateTitle(entry: ReportPositionEntry) {
  description.value = entry.description ?? '';
  quantity.value = entry.quantity;
  unitId.value = entry.unitId
}

</script>

<style scoped>

</style>