import type ResponseWrapper from '@/model/ResponseWrapper';
import { getDefaultResponseWrapper, ResponseKey } from '@/model/ResponseWrapper'
import axios from 'axios'
import { useToast } from 'vue-toast-notification'
import { Utils } from '@/client/utils'
import { KeycloakService } from '@/service/keycloakService'

export class API {

  static readonly backendURL = window.API_URL != '${API_URL}'
    ? window.API_URL : 'http://localhost:8080';
  static readonly apiBaseUrl = `${this.backendURL}/api/v1/`;

  static readonly frontendURL = window.FRONTEND_URL != '${FRONTEND_URL}'
    ? window.FRONTEND_URL : 'http://localhost:5173';

  static readonly api = axios.create({
    baseURL: this.apiBaseUrl
  });

  static $toast = useToast();

  static init() {
    this.setApiInterceptors();
  }

  static async get<T>(segment: PathSegment): Promise<ResponseWrapper<T>>;
  static async get<T>(segment: PathSegment, id: string): Promise<ResponseWrapper<T>>;
  static async get<T>(firstSegment: PathSegment, id: string, secondSegment: PathSegment): Promise<ResponseWrapper<T>>;
  static async get<T>(url: string): Promise<ResponseWrapper<T>>;

  static async get<T>(firstSegmentOrUrl: PathSegment | string, id?: string, secondSegment?: PathSegment) {
    const url = `${firstSegmentOrUrl}${id ? '/' + id : ''}${secondSegment ? '/' + secondSegment : ''}`;
    try {
      console.log(`GET ${url}`);
      return (await this.api.get<ResponseWrapper<T>>(url)).data;
    } catch (error) {
      return this.catchError(error);
    }
  }

  static async getWithParameters<T>(segment: PathSegment, parameters: Map<string, string>): Promise<ResponseWrapper<T>>;
  static async getWithParameters<T>(segment: PathSegment, id: string, parameters: Map<string, string>): Promise<ResponseWrapper<T>>;
  static async getWithParameters<T>(firstSegment: PathSegment, id: string, secondSegment: PathSegment, parameters: Map<string, string>): Promise<ResponseWrapper<T>>;
  static async getWithParameters(firstSegment: PathSegment, idOrParam: string | Map<string, string>, secondSegmentOrParam?: PathSegment | Map<string, string>, param?: Map<string, string>) {
    let url = firstSegment.toString();
    url += typeof idOrParam !== 'string' ? '?' : '/' + idOrParam;
    if (secondSegmentOrParam) url += secondSegmentOrParam instanceof Map ? '?' : '/' + secondSegmentOrParam
    url += param ? '?' : ''

    const parameters = param ? param : (secondSegmentOrParam ? secondSegmentOrParam as Map<string, string> : (idOrParam) ? idOrParam as Map<string, string> : new Map<string, string>());
    let parameterString = '';
    for (const key of parameters.keys()) {
      parameterString += `${key}=${parameters.get(key)}&`
    }
    parameterString = parameterString.substring(0, parameterString.length - 1);

    return this.get(url + parameterString);
  }

  static async post<T>(segment: string | PathSegment, data: any): Promise<ResponseWrapper<T> | ResponseWrapper<null>> {
    try {
      return (await this.api.post<ResponseWrapper<T>>(`${segment}`, data)).data;
    } catch (error) {
      return this.catchError(error);
    }
  }

  static async put<T>(segment: PathSegment, data: any) {
    try {
      return (await this.api.put<ResponseWrapper<T>>(`${segment}`, data)).data;
    } catch (error) {
      return this.catchError(error);
    }
  }

  static async delete(segment: PathSegment, id: string) {
    try {
      return (await this.api.delete<ResponseWrapper<null>>(`${segment}/${id}`)).data;
    } catch (error) {
      return this.catchError(error);
    }
  }

  static async postMultiPart<T>(path: string, formData: FormData) {
    const config = {
      headers: { 'Content-Type': 'multipart/form-data'},
    };
    try{
      return (await this.api.post<ResponseWrapper<T>>(`${this.apiBaseUrl}${path}`, formData, config)).data;
    } catch (error) {
      return this.catchError(error);
    }
  }

  private static setApiInterceptors() {
    this.api.interceptors.request.use(
      async (config) => {
        const token = await KeycloakService.getToken();
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error) => {
        // Do something with request error
        return Promise.reject(error);
      }
    );
  }

  private static getBearerHeader() {
    return {
      headers: {
        Authorization: `Bearer ${KeycloakService.keycloak.token}`
      }
    }
  }

  private static catchError(error: unknown): ResponseWrapper<null> {
    if (axios.isAxiosError(error)) {
      if (error.response) {
        return error.response.data
      }
    }
    return getDefaultResponseWrapper();
  }

  static async getDataObject<T>(pathSegment: PathSegment, id: string = '', objectString: string = 'Objekt') {
    try {
      const response = id == '' ? await API.get<T>(pathSegment) : await API.get<T>(pathSegment, id);
      if (response.key == ResponseKey.OK) {
        return response.data;
      } else {
        this.logApiError(objectString, 'geladen', response.message, response.key);
      }
    } catch (error) {
      this.logApiError(objectString, 'geladen', Utils.getError(error));
    }
  }

  static async wrapGet<T>(get: Promise<ResponseWrapper<T>>, objectString: string = 'Objekt') {
    try {
      const response = await get;
      if (response.key == ResponseKey.OK) {
        return response.data;
      } else {
        this.logApiError(objectString, 'geladen', response.message, response.key);
      }
    } catch (error) {
      this.logApiError(objectString, 'geladen', Utils.getError(error));
    }
  }

  static async createDataObject<T, U = undefined>(pathSegment: PathSegment, dataObject: T | U, objectString: string = 'Objekt') {
    try {
      const response = await API.post<T>(pathSegment, dataObject);
      if (response.key && response.key == ResponseKey.CREATED && response.data) {
        return response.data;
      } else {
        this.logApiError(objectString, 'erstellt', response.message, response.key);
      }
    } catch (error) {
      this.logApiError(objectString, 'erstellt', Utils.getError(error));
    }
  }

  static async updateDataObject<T, U = undefined>(pathSegment: PathSegment, dataObject: T | U, objectString: string = 'Objekt') {
    try {
      const response = await API.put<T>(pathSegment, dataObject);
      if (response.key == ResponseKey.UPDATED && response.data) {
        return response.data;
      } else {
        this.logApiError(objectString, 'aktuallisiert', response.message, response.key);
      }
    } catch (error) {
      this.logApiError(objectString, 'aktuallisiert', Utils.getError(error));
    }
  }
  static async deleteDataObject(pathSegment: PathSegment, id: string, objectString: string = 'Objekt') {
    try {
      const response = await API.delete(pathSegment, id);
      if (response.key == ResponseKey.DELETED) return true;
      else {
        this.logApiError(objectString, 'gelöscht', response.message, response.key);
      }
    } catch (error) {
      this.logApiError(objectString, 'gelöscht', Utils.getError(error));
    }
    return false;
  }

  static async getImage(pathSegment: PathSegment) {
    try {
      console.log(`GET IMAGE ${pathSegment}`);
      return (await this.api.get(pathSegment, {
        responseType: 'blob',
      })).data;
    } catch (error) {
      return this.catchError(error);
    }
  }

  static logApiError(object:string, action: string, message: string, key?: ResponseKey) {
    this.$toast.error(`${object} konnte nicht ${action} werden: ${message}${key ? ' (' + key + ')' : ''}`);
    console.error(`${message} ${key ?? ''}`);
  }

}

export enum PathSegment {
  TYPES = "types",
  COMPANIES = "companies",
  COMPANIES_LOGO = "companies/logo",
  COMPANIES_SIGNATURE = "companies/signature",
  CUSTOMERS = "customers",
  PROJECTS = "projects",
  INVOICES = "invoices",
  WORK_REPORT_POSITION_PRESET = "workerPresets",
  REPORT_POSITIONS = "reportPositions",
  DISCOUNTS = "discounts",
  INVOICE_INFO = "info",
  CATEGORIES = "categories",
  CHILDREN = "children",
  LATEST_REPORT_DESCRIPTIONS = "latestReportDescription",
  TRANSCRIBE = "transcribe",
  SETTINGS = "settings",
  TYPES_UNITS = "types/units",
  TYPES_DISCOUNT_TYPES = "types/discountTypes",
  CUSTOM_FIELDS = "customFields",
  OFFERS = "offers",
  OFFER_POSITIONS = "offerPositions",
  OFFER_INFO = "offerInfo",
  OFFER_DISCOUNTS = "offerDiscounts",
  LOCATIONS_CORRECT = "locations/correct",
  LOCATIONS_LOCATE = "locations/locate",
  POSITIONS = "positions",
  // ...
}


