import { createRouter, createWebHistory } from 'vue-router';
import DashboardView from '../views/dashboard/DashboardView.vue';
import CreateCompany from '../views/company/CreateCompanyView.vue';
import LoginView from '../views/login/LoginView.vue';
import InitializationView from '@/views/initial/InitializationView.vue'
import ProjectView from '@/views/project/ProjectView.vue'
import SubprojectView from '@/views/subproject/CreateEditSubprojectView.vue'
import EditProjectView from '@/views/project/CreateEditProjectView.vue'
import CreateEditReportPosition from '@/views/reportPosition/CreateEditReportPosition.vue'
import CreateEditCustomer from '@/views/customer/CreateEditCustomer.vue'
import CustomerView from '@/views/customer/CustomerView.vue'
import AllCustomersView from '@/views/customer/AllCustomersView.vue'
import CreateEditDiscount from '@/views/discount/CreateEditDiscount.vue'
import CreateEditCategory from '@/views/category/CreateEditCategory.vue'
import AllCategoriesView from '@/views/category/AllCategoriesView.vue'
import SettingsView from '@/views/settings/SettingsView.vue'
import PresetsAndTypesView from '@/views/presetsAndTypes/PresetsAndTypesView.vue'
import AllProjectsView from '@/views/project/AllProjectsView.vue'
import UseCaseSelectionView from '@/views/UseCaseSelectionView.vue'
import ProfileView from '@/views/profile/ProfileView.vue'
import CreateEditOfferView from '@/views/offer/CreateEditOfferView.vue'
import CreateEditOfferPosition from '@/views/offer/CreateEditOfferPosition.vue'
import CreateEditTransportReport from '@/views/reportPosition/CreateEditTransportReport.vue'
import CreateEditOfferDiscount from '@/views/offer/CreateEditOfferDiscount.vue'

const routeNames = {
  DASHBOARD: "dashboard",
  CREATE_COMPANY: "createCompany",
  EDIT_COMPANY: "editCompany",
  LOGIN: "login",
  INIT: "init",
  CUSTOMER: "customer",
  CUSTOMERS: "customers",
  CREATE_CUSTOMER: "createCustomer",
  EDIT_CUSTOMER: "editCustomer",
  PROJECT: "project",
  PROJECTS: "projects",
  CREATE_PROJECT: "createProject",
  EDIT_PROJECT: "editProject",
  WORK_REPORT: "workReport",
  MATERIAL_REPORT: "materialReport",
  CREATE_SUBPROJECT: "createSubproject",
  EDIT_SUBPROJECT: "editSubproject",
  CREATE_REPORT_POSITION: "createWorkReport",
  EDIT_REPORT_POSITION: "editWorkReport",
  CREATE_TRANSPORT_REPORT: "createTransportReport",
  EDIT_TRANSPORT_REPORT: "editTransportReport",
  CREATE_DISCOUNT: "createDiscount",
  EDIT_DISCOUNT: "editDiscount",
  CATEGORIES_ROOT: "categoriesRoot",
  CATEGORIES_CHILD: "categoriesChild",
  CREATE_CATEGORY: "createCategory",
  EDIT_CATEGORY: "editCategory",
  PRESETS_AND_TYPES: "presetsAndTypes",
  SETTINGS: "settings",
  USE_CASE: "useCase",
  PROFILE: "profile",
  CREATE_OFFER: "createOffer",
  EDIT_OFFER: "editOffer",
  CREATE_OFFER_POSITION: "createOfferPosition",
  EDIT_OFFER_POSITION: "editOfferPosition",
  CREATE_OFFER_DISCOUNT: "createOfferDiscount",
  EDIT_OFFER_DISCOUNT: "editOfferDiscount",
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  // this will reset the view to the top on url change
  // it is not working correctly when setting query parameter for e.g. filtering
  // scrollBehavior(to, from, savedPosition) {
  //   if (savedPosition) {
  //     return savedPosition;
  //   } else {
  //     return { left: 0, top: 0 };
  //   }
  // },
  routes: [
    {
      path: '/',
      alias: '/dashboard',
      name: routeNames.DASHBOARD,
      component: DashboardView
    },
    {
      path: '/company/create',
      name: routeNames.CREATE_COMPANY,
      component: CreateCompany
    },
    {
      path: '/company/edit',
      name: routeNames.EDIT_COMPANY,
      component: CreateCompany
    },
    {
      path: '/login',
      name: routeNames.LOGIN,
      component: LoginView
    },
    {
      path: '/init',
      name: routeNames.INIT,
      component: InitializationView
    },
    {
      path: '/customer/:id',
      name: routeNames.CUSTOMER,
      component: CustomerView
    },
    {
      path: '/customers',
      name: routeNames.CUSTOMERS,
      component: AllCustomersView
    },
    {
      path: '/customer/create',
      name: routeNames.CREATE_CUSTOMER,
      component: CreateEditCustomer
    },
    {
      path: '/customer/:id/edit',
      name: routeNames.EDIT_CUSTOMER,
      component: CreateEditCustomer
    },
    {
      path: '/project/:id',
      name: routeNames.PROJECT,
      component: ProjectView
    },
    {
      path: '/projects',
      name: routeNames.PROJECTS,
      component: AllProjectsView
    },
    {
      path: '/project/create',
      name: routeNames.CREATE_PROJECT,
      component: EditProjectView
    },
    {
      path: '/project/:id/edit',
      name: routeNames.EDIT_PROJECT,
      component: EditProjectView
    },
    {
      path: '/subproject/create',
      name: routeNames.CREATE_SUBPROJECT,
      component: SubprojectView
    },
    {
      path: '/subproject/:id/edit',
      name: routeNames.EDIT_SUBPROJECT,
      component: SubprojectView
    },
    {
      path: '/reportPosition/create',
      name: routeNames.CREATE_REPORT_POSITION,
      component: CreateEditReportPosition
    },
    {
      path: '/reportPosition/:id/edit',
      name: routeNames.EDIT_REPORT_POSITION,
      component: CreateEditReportPosition
    },
    {
      path: '/transportReport/create',
      name: routeNames.CREATE_TRANSPORT_REPORT,
      component: CreateEditTransportReport
    },
    {
      path: '/transportReport/:id/edit',
      name: routeNames.EDIT_TRANSPORT_REPORT,
      component: CreateEditTransportReport
    },
    {
      path: '/discount/create',
      name: routeNames.CREATE_DISCOUNT,
      component: CreateEditDiscount
    },
    {
      path: '/discount/:id/edit',
      name: routeNames.EDIT_DISCOUNT,
      component: CreateEditDiscount
    },
    {
      path: '/categories',
      name: routeNames.CATEGORIES_ROOT,
      component: AllCategoriesView,
      children: [
        {
          path: ':ids*',
          name: routeNames.CATEGORIES_CHILD,
          component: AllCategoriesView
        }
      ]
    },
    {
      path: '/category/create',
      name: routeNames.CREATE_CATEGORY,
      component: CreateEditCategory
    },
    {
      path: '/category/:id/edit',
      name: routeNames.EDIT_CATEGORY,
      component: CreateEditCategory
    },
    {
      path: '/presetsAndTypes',
      name: routeNames.PRESETS_AND_TYPES,
      component: PresetsAndTypesView
    },
    {
      path: '/settings',
      name: routeNames.SETTINGS,
      component: SettingsView
    },
    {
      path: '/useCase',
      name: routeNames.USE_CASE,
      component: UseCaseSelectionView
    },
    {
      path: '/profile',
      name: routeNames.PROFILE,
      component: ProfileView
    },
    {
      path: '/offer/create',
      name: routeNames.CREATE_OFFER,
      component: CreateEditOfferView
    },
    {
      path: '/offer/:id/edit',
      name: routeNames.EDIT_OFFER,
      component: CreateEditOfferView
    },
    {
      path: '/offerPosition/create',
      name: routeNames.CREATE_OFFER_POSITION,
      component: CreateEditOfferPosition
    },
    {
      path: '/offerPosition/:id/edit',
      name: routeNames.EDIT_OFFER_POSITION,
      component: CreateEditOfferPosition
    },
    {
      path: '/offerDiscount/create',
      name: routeNames.CREATE_OFFER_DISCOUNT,
      component: CreateEditOfferDiscount
    },
    {
      path: '/offerDiscount/:id/edit',
      name: routeNames.EDIT_OFFER_DISCOUNT,
      component: CreateEditOfferDiscount
    },
  ]
})

export default router
export {
  routeNames
}
